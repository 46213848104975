import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useGeneralEnquiry from "./useGeneralEnquiry";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import ReasonModal from "./ReasonModal";

const GeneralEnquiry = () => {
  const {
    filters,
    activeFilter,
    isLoading,
    actionOptions,
    generalEnquiryState,
    generalEnquiryData,
    isFetching,
    paginationOptions,
    statusModal,
    statusReason,
    errors,
    handleSubmitStatusChange,
    setStatusReason,
    closeStatusReasonModal,
    handlePageSize,
    handlePagination,
    handleSort,
    getRow,
    handleClearClick,
    handleActionChange,
    handleFilter,
    handleSearch,
  } = useGeneralEnquiry();
  return (
    <>
      <HeadingGroup title={"General Enquiry"} className={`pro-mb-4`} />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col">
            <SearchFilters
              data={filters}
              activeFilter={activeFilter}
              handleButtonGroupChange={handleFilter}
              onSearchInput={handleSearch}
              showActions={false}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              actionOptions={actionOptions?.filter((item) => item.value === 0)}
              onActionOptionChange={handleActionChange}
              handleClear={handleClearClick}
              searchInputProps={{ value: generalEnquiryState?.search }}
            />
          </div>
        </div>

        {generalEnquiryData?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={generalEnquiryData?.data?.data || []}
            uniqueID={"_id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            showCheckBox={false}
            deletable={false}
            //   handleEdit={handleEditAction}
            clear={generalEnquiryState.clearSelection}
            multiSelect={false}
            assignable={false}
            fields={generalEnquiryData?.data?.fields}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={generalEnquiryState?.currentPageSize}
          />
        )}

        {generalEnquiryData?.data?.data?.length > 0 && (
          <Pagination
            currentPage={generalEnquiryState.currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === generalEnquiryState?.currentPageSize
            )}
            totalPageCount={Math.ceil(
              generalEnquiryData?.data?.total_count /
                generalEnquiryState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
      </div>

      <ModalLayout
        show={statusModal}
        handleClose={closeStatusReasonModal}
        backdrop="static"
      >
        <div className="pro-m-5">
          <ReasonModal
            setStatusReason={setStatusReason}
            statusReason={statusReason}
            handleSubmitStatusChange={handleSubmitStatusChange}
            closeModal={closeStatusReasonModal}
            errors={errors}
          />
        </div>
      </ModalLayout>
    </>
  );
};

export default GeneralEnquiry;
