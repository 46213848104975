import { createSlice } from "@reduxjs/toolkit";
import { addDays } from "date-fns";
import { getFormatedDate } from "../../../utils/functions/table";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  currentBranchFilter: [],
  startDate: getFormatedDate(addDays(new Date(), -30)),
  endDate: getFormatedDate(new Date()),
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
  showViewModal: false,
  viewDetails: [],
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = paymentSlice.actions;

export default paymentSlice.reducer;
