import DashboardCardShimmer from "../../Global/Shimmers/DashboardCardShimmer";
import DashboardCard from "../DashboardCard";
import Style from "./dashboardHero.module.scss";

const DashboardHero = ({
  data,
  obj,
  transactions = false,
  loading,
  listingType,
  shimmerCount,
  totalValue,
  cardsPerLine = 4,
  isOrder = false,
}) => {
  return (
    <div className={`pro-w-100`}>
      <div
        className={`row g-4 g-xl-2 row-cols-2 row-cols-lg-4 row-cols-xxl-${cardsPerLine}`}
      >
        {!loading ? (
          <>
            {Object.keys(data || {})?.map((key) => {
              return (
                <>
                  <div
                    className={`col-4 ${
                      !transactions ? Style.dashboardCard_root : ""
                    }`}
                    onClick={
                      !transactions
                        ? () => obj?.[key]?.handleClick(obj?.[key]?.label)
                        : () => {}
                    }
                  >
                    <DashboardCard
                      icon={obj?.[key]?.icon}
                      title={obj?.[key]?.title}
                      data={data?.[key]}
                      prev={obj?.[key]?.prev}
                      active={obj?.[key]?.label === listingType ? true : false}
                      transactions={transactions}
                      dualTotal={obj?.[key]?.isDualValue}
                      totalValue={totalValue}
                      isOrder={isOrder}
                    />
                  </div>
                </>
              );
            })}
          </>
        ) : (
          [...Array(shimmerCount)].map((_, i) => (
            <div className="col-4">
              <DashboardCardShimmer key={i} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DashboardHero;
