import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  currentFilter: 3,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
};

const generalEnquirySlice = createSlice({
  name: "generalEnquiry",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = generalEnquirySlice.actions;

export default generalEnquirySlice.reducer;
