import { Image, ModalLayout } from "@wac-ui-dashboard/wac_component_library";
import useDocumentDetails from "./useDocumentDetails";
import EmptyData from "../../../Global/EmptyData";
import { ImageCard } from "../../../Global/ImageCard";
import Style from "./documentDetails.module.scss";
import Assets from "../../../../assets/Assets";
import OverviewShimmer from "../../../Global/Shimmers/OverviewShimmer";
const DocumentDetails = () => {
  const {
    mainData,
    isFetching,
    showImageModal,
    imageData,
    alt,
    docId,
    itemStatus,
    note,
    itemRejected,
    rejectReason,
    documentType,
    downloadLoading,
    handleDownloadPdf,
    closeImageModal,
    handleViewImage,
    handleVerification,
    setItemRejected,
    setNote,
  } = useDocumentDetails();
  // const obj = {
  //   1: "Passport(Front)",
  //   2: "Passport(Back)",
  //   3: "Ticket/ Vsa",
  //   4: "Pan Card",
  //   5: "Education Loan",
  // };

  const obj = {
    1: "Passport(Front)",
    2: "Passport(Back)",
    3: "Ticket",
    4: "Visa",
    5: "Other Documents",
    6: "Pan Card",
    7: "Education Loan",
  };

  const statusObj = {
    0: "Pending",
    1: "Approved",
    2: "Rejected",
  };

  const handleStatusIcon = (id) => {
    return statusObj[id];
  };
  const handleImageTitle = (id) => {
    return obj[id];
  };
  return (
    <>
      <h6 className="pro-ttl h6 pro-mb-4 pro-mt-5">Document Details</h6>
      {isFetching ? (
        <div className="pro-p-4 pro-w-100">
          <OverviewShimmer />
        </div>
      ) : (
        <div className={`pro-w-100 pro-pb-6`}>
          {/* {mainData?.data?.data?.documents && ( */}
          <div className="pro-w-100">
            <div
              className={`pro-d-flex pro-flex-wrap pro-gap-5 ${Style.document_card_wrap}`}
            >
              {!mainData?.data?.data?.documents ? (
                <div className={`${Style.empty_wrapper} pro-w-100`}>
                  <EmptyData />
                </div>
              ) : (
                mainData?.data?.data?.documents?.map((item, index) =>
                  item?.id_type !== 6 ? (
                    <div className={`${Style.root}`} key={index}>
                      <div className={`${Style.root_inner}`}>
                        <div
                          className={`${Style.root_image}`}
                          onClick={(e) => handleViewImage(item)}
                        >
                          <Image
                            src={
                              (item?.document_type === "pdf"
                                ? // ? generatePdfThumbnail(item?.name)
                                  Assets.PDF_IMAGE
                                : item?.name) ??
                              (item?.gender?.name === "Female"
                                ? Assets.FEMALEPROFILE
                                : Assets.MALEPROFILE)
                            }
                            width={146}
                            height={123}
                            alt={alt || `user image`}
                          />
                        </div>
                        <div
                          className={`${Style.badge} pro-ps-1 pro-bg-light pro-rounded-3 pro-d-flex pro-items-center`}
                        >
                          <span className={`${Style.badge_text} pro-fw-medium`}>
                            {handleStatusIcon(item?.status)}
                          </span>
                          <div
                            className={`pro-check-box rounded-tick ${Style.round_tick}`}
                          >
                            <img
                              alt="badge"
                              src={`
                                    ${
                                      item?.status === 0
                                        ? Assets.YELLOWCHECK
                                        : item?.status === 1
                                        ? Assets.GREENCHECK
                                        : item?.status === 2
                                        ? Assets.REDCHECK
                                        : ""
                                    }
                                    `}
                            />
                          </div>
                        </div>
                        <div className={`pro-pt-3`}>
                          <p className="pro-fw-medium pro-mb-0">
                            {handleImageTitle(item.id_type)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )
              )}
            </div>
            <div className={`${Style.document_card_wrap} pro-pt-5`}>
              <h6 className="pro-ttl h6 pro-mb-0">Pancard Verification</h6>
              <div className={`pro-w-100`}>
                {!mainData?.data?.data?.documents ? (
                  <EmptyData />
                ) : (
                  mainData?.data?.data?.documents?.map((item, index) =>
                    item?.id_type === 6 ? (
                      <>
                        <div className="pro-d-flex pro-gap-5 pro-pt-5">
                          <div className={`${Style.root}`} key={index}>
                            <div className={`${Style.root_inner}`}>
                              <div
                                onClick={(e) => handleViewImage(item)}
                                className={Style.root_image}
                              >
                                <Image
                                  src={
                                    (item?.document_type === "pdf"
                                      ? // ? generatePdfThumbnail(item?.name)
                                        Assets.PDF_IMAGE
                                      : item?.name) ??
                                    (item?.gender?.name === "Female"
                                      ? Assets.FEMALEPROFILE
                                      : Assets.MALEPROFILE)
                                  }
                                  width={146}
                                  height={123}
                                  alt={alt || `user image`}
                                />
                              </div>
                              <div
                                className={`${Style.badge} pro-ps-1 pro-bg-light pro-rounded-3 pro-d-flex pro-items-center`}
                              >
                                <span
                                  className={`${Style.badge_text} pro-fw-medium`}
                                >
                                  {handleStatusIcon(item?.status)}
                                </span>
                                <div
                                  className={`pro-check-box rounded-tick ${Style.round_tick}`}
                                >
                                  <img
                                    alt="badge"
                                    src={`
                                    ${
                                      item?.status === 0
                                        ? Assets.YELLOWCHECK
                                        : item?.status === 1
                                        ? Assets.GREENCHECK
                                        : item?.status === 2
                                        ? Assets.REDCHECK
                                        : ""
                                    }
                                    `}
                                  />
                                </div>
                              </div>
                              <div className={`pro-pt-3`}>
                                <p className="pro-fw-medium pro-mb-0">
                                  {handleImageTitle(item.id_type)}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className={`${Style.document_detail}`}>
                            <p
                              className={`pro-mb-1 pro-fw-medium pro-font-sm  ${Style.document_text}`}
                            >
                              PAN Card Number
                            </p>
                            <div
                              className={`${Style.document_number_box} pro-rounded-3 pro-w-100 pro-d-flex pro-items-center pro-justify-between`}
                            >
                              <span>{mainData?.data?.data?.pan_number}</span>
                              {mainData?.data?.data?.is_pan_verified === 1 && (
                                <span
                                  class={`material-symbols-outlined x4 ${Style.tick}`}
                                >
                                  check
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  )
                )}
              </div>
            </div>
          </div>
          {/* )} */}
        </div>
      )}

      <ModalLayout
        show={showImageModal}
        handleClose={closeImageModal}
        backdrop="static"
      >
        <ImageCard
          data={imageData}
          documentType={documentType}
          docId={docId}
          status={itemStatus}
          statusObj={statusObj}
          verification={handleVerification}
          handleClose={closeImageModal}
          itemRejected={itemRejected}
          setItemRejected={setItemRejected}
          note={note}
          setNote={setNote}
          rejectReason={rejectReason}
          handleDownloadPdf={handleDownloadPdf}
          downloadLoading={downloadLoading}
        />
      </ModalLayout>

      {/* <div style={{ position: "absolute", left: "-9999px" }}>
        <DocumentItem
          ref={downloadRef}
          documentType={documentType}
          data={imageData}
        />
      </div> */}
    </>
  );
};

export default DocumentDetails;
