import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import { capitalizeOnSpace } from "../../../../utils/functions/table";
import useUserForm from "./useUserForm";
import Select from "react-select";
import CountryCodes from "../../../../utils/components/countryCode";
import Style from "./userForm.module.scss";
import { limitStrLength } from "../../../../utils/functions/table";
const UserForm = ({
  refetch,
  closeModal,
  isStickyFooter,
  formData,
  hasEditPermission,
}) => {
  const {
    formik,
    selectedItemsDetails,
    handleProfilePhoto,
    profileImagePreview,
    profilefileInputRef,
  } = useUserForm({ refetch, closeModal });

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Name*"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${
          formik?.errors?.name && formik?.touched?.name && "error"
        }`}
        {...formik.getFieldProps("name")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik?.errors?.name && formik?.touched?.name}
        errorMessage={formik?.errors?.name}
        disabled={hasEditPermission ? false : true}
      />

      <Input
        label={"Email*"}
        type="email"
        id="email"
        name="email"
        className={`pro-input lg ${
          formik?.errors?.email && formik?.touched?.email && "error"
        }`}
        {...formik.getFieldProps("email")}
        error={formik?.errors?.email && formik?.touched?.email}
        errorMessage={formik?.errors?.email}
        // disabled={hasEditPermission ? false : true}
      />

      <Input
        label={"DOB*"}
        type="date"
        id="dob"
        name="dob"
        className={`pro-input lg ${
          formik?.errors?.dob && formik?.touched?.dob && "error"
        }`}
        {...formik.getFieldProps("dob")}
        // onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik?.errors?.dob && formik?.touched?.dob}
        errorMessage={formik?.errors?.dob}
        disabled={hasEditPermission ? false : true}
      />

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <div className="input-country-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Mobile Number *
            </label>
            <div className="input-main-wrap">
              <div className="code-wrap pro-mb-4 ">
                <Select
                  name="country_code"
                  options={CountryCodes}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("country_code", selectedOption)
                  }
                  className={` ${
                    formik?.errors?.country_code &&
                    formik?.touched?.country_code &&
                    "error"
                  }
                multi-select country-select`}
                  classNamePrefix={`pro-input`}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.country_code}
                  isDisabled={hasEditPermission ? false : true}
                />
              </div>
              <input
                pattern="[0-9]{10}"
                type="text"
                name="phone_number"
                placeholder="phone_number"
                onChange={(event) => {
                  // Remove any non-digit characters from the input
                  const numericValue = event?.target?.value.replace(/\D/g, "");
                  // Update the formik value with the numeric value
                  formik.setFieldValue("phone_number", numericValue);
                }}
                className={`pro-input lg ${
                  formik?.errors?.phone_number &&
                  formik.touched?.phone_number &&
                  "error"
                }`}
                onBlur={formik?.handleBlur}
                value={formik?.values?.phone_number}
                disabled={hasEditPermission ? false : true}
              />
            </div>
            {formik?.touched?.phone_number && formik?.errors?.phone_number && (
              <div className="error-text">{formik?.errors?.phone_number}</div>
            )}
            {formik?.errors?.phone_number === undefined &&
              formik?.touched?.country_code &&
              formik?.errors?.country_code && (
                <div className="error-text">{formik?.errors?.country_code}</div>
              )}
          </div>
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Profile Photo
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched.image && formik.errors.image && " error"
            }`}
            id="profile_url"
            name="profile_url"
            onBlur={formik.handleBlur("profile_url")}
            onChange={(e) => handleProfilePhoto(e)}
            disabled={hasEditPermission ? false : true}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.profile_image?.name !== undefined
                  ? limitStrLength(formik?.values?.profile_image?.name, 30)
                  : selectedItemsDetails !== "" &&
                    selectedItemsDetails?.profile_image_name
                  ? selectedItemsDetails?.profile_image_name
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched?.profile_image && formik?.errors?.profile_image && (
            <span className="error-text">{formik?.errors?.profile_image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={profileImagePreview}
            alt={`branch - 01`}
          />
        </div>
      </div>
      {/* toggle button start  */}
      <div className="pro-toggle">
        <div className="pro-toggle-box">
          <input
            id="status"
            name="status"
            type="checkbox"
            checked={Boolean(formik.values?.status) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "status",
                !Boolean(formik.values?.status) ?? 0
              );
            }}
            disabled={hasEditPermission ? false : true}
          />
          <span></span>
        </div>
        <label>Status</label>
      </div>
      {/* toggle button end  */}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        {hasEditPermission !== 0 && (
          <Button
            className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
            type="submit"
            // disabled={hasUpdatePermission === true ? false : true}
            onClick={formik.handleSubmit}
          >
            Update
          </Button>
        )}
      </div>
    </div>
  );
};

export default UserForm;
