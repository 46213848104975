import { useFormik } from "formik";
import { useRef } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Branches/branchSlice";
// import { createNewPincode, updatePincode } from '../../../../../store/slices/Branches/branchSlice'
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { createPincode, updatePincode } from "./api";

const usePincodeForm = ({ refetch, closeModal }) => {
  const { branch_id } = useParams();
  const activeProfile = sessionStorage.getItem("active");
  const dispatch = useDispatch();
  const { is_edit, selectedId, selectedItemsDetails } = useSelector(
    (state) => state.branchPincode
  );
  const profileInputRef = useRef(null);
  const validation = Yup.object({
    pincode: Yup.number()
      .typeError("Must be a number")
      .integer("PIN code must be an integer")
      .positive("Must be a positive number")
      .min(100000, "PIN code must have at least 6 digits")
      .max(999999, "PIN code can have at most 6 digits")
      .required("Required Pincode"),
    handling_charge: Yup.number()
      .typeError("Must be a number")
      .required("Required Handling Charge"),
    area: Yup.string().required("Area Required"),
  });

  const formik = useFormik({
    initialValues: {
      pincode: selectedItemsDetails !== "" ? selectedItemsDetails?.pincode : "",
      handling_charge:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.handling_charge
          : "",
      area: selectedItemsDetails !== "" ? selectedItemsDetails?.area : "",
      status:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.status !== null
            ? selectedItemsDetails?.status
            : 1
          : 1,
    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        branch_id: branch_id ?? activeProfile,
        pincode_id: is_edit ? selectedId : "",
        pincode: values?.pincode,
        handling_charge: values?.handling_charge,
        area: values?.area,
        status: +values?.status,
      };
      let formData = new FormData();
      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });
      if (selectedItemsDetails !== "") {
        formData.append("_method", "PUT");
        updatePincode(formData).then((response) => {
          if (response?.data?.success === true) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            toast.success(response?.data?.message);
          } else if (response?.success === false) {
            formik.setErrors(response?.data?.pincode?.[0]);
            toast?.error(response?.data?.pincode?.[0]);
          } else toast.error(response?.payload?.message);
        });
      } else {
        createPincode(formData)
          .then((response) => {
            if (response?.data?.success === true) {
              resetForm();
              refetch();
              closeModal?.();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              toast.success(response.data.message);
            } else if (response?.success === false) {
              formik.setErrors(response?.data?.pincode?.[0]);
              toast.error(response?.data?.pincode?.[0]);
            } else {
              // Handle missing response
              toast.error("No response received");
            }
          })
          .catch((error) => {
            // Handle API request error
            toast.error("An error occurred while making the request");
          });
      }
    },
  });

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return { formik, handleCloseModal, profileInputRef, selectedItemsDetails };
};

export default usePincodeForm;
