import React from "react";
import useInvoice from "./useInvoice";
import { useSelector } from "react-redux";
import Style from "./invoice.module.scss";
import Assets from "../../../../assets/Assets";
import InvoiceShimmer from "../../../Global/Shimmers/InvoiceShimmer";

const Invoice = () => {
  const {
    handleDownloadClick,
    handlePrintClick,
    globalState,
    invoice,
    invoiceRef,
  } = useInvoice();
  const { invoiceLoading } = useSelector((state) => state.orders);
  return (
    //FIXME style
    <div className="pro-py-5 pro-pt-0">
      {invoiceLoading ? (
        <InvoiceShimmer />
      ) : (
        <div className="pro-w-100">
          <div ref={invoiceRef} className="pro-mx-5 pro-pt-5">
            <div className="pro-w-100 pro-pb-5">
              <div className={`${Style.logoWrapper} pro-pb-3 pro-pt-2`}>
                <figure className="pro-mb-0">
                  <img
                    src={
                      globalState.currentTheme === "light" ||
                      globalState.currentTheme === null
                        ? Assets.HEADERLOGO
                        : Assets.HEADERLOGODARK
                    }
                    alt="logo"
                  />
                </figure>
              </div>
              <table className={Style.user_detail_table}>
                <tbody>
                  <tr>
                    <td>
                      <label
                        htmlFor=""
                        className={`pro-font-xs pro-mb-0 ${Style.label}`}
                      >
                        Name
                      </label>
                      <p className="pro-mb-0 pro-fw-bold">
                        {invoice?.user_name}
                      </p>
                    </td>
                    <td>
                      <label
                        htmlFor=""
                        className={`pro-font-xs pro-mb-0 ${Style.label}`}
                      >
                        Branch
                      </label>
                      <p className="pro-mb-0 pro-fw-bold">{invoice?.branch}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label
                        htmlFor=""
                        className={`pro-font-xs pro-mb-0 ${Style.label}`}
                      >
                        Order Type
                      </label>
                      <p className="pro-mb-0 pro-fw-bold">
                        {invoice?.order_type}
                      </p>
                    </td>
                    <td>
                      <label
                        htmlFor=""
                        className={`pro-font-xs pro-mb-0 ${Style.label}`}
                      >
                        Order Number
                      </label>
                      <p className="pro-mb-0 pro-fw-bold">
                        {invoice?.order_number}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label
                        htmlFor=""
                        className={`pro-font-xs pro-mb-0 ${Style.label}`}
                      >
                        Date
                      </label>
                      <p className="pro-mb-0 pro-fw-bold">{invoice?.date}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <table className={Style.invoice_table}>
              <tbody>
                <tr>
                  <th>#</th>
                  <th>Currency</th>
                  <th>Type</th>
                  <th>Quantity</th>
                  <th>Unit Amount</th>
                  <th>Amount</th>
                </tr>
                {invoice?.product_details?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.currency_code}</td>
                    <td>{item?.name}</td>
                    <td>{item?.quantity}</td>
                    <td>₹ {item?.unit_amount}</td>
                    <td>₹ {item?.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {invoice?.gst?.IGST ? (
              <div className={`pro-d-flex pro-justify-end pro-gap-3 pro-pt-2`}>
                <p className="pro-mb-0 pro-font-xs pro-flex-shrink-0">IGST</p>
                {
                  <div className="pro-text-end">
                    <p className="pro-mb-0" style={{ minWidth: 140 }}>
                      ₹ {invoice?.gst?.IGST}
                    </p>
                  </div>
                }
              </div>
            ) : (
              <>
                <div
                  className={`pro-d-flex pro-justify-end  pro-gap-3 pro-pt-2`}
                >
                  <p className="pro-mb-0 pro-font-xs pro-flex-shrink-0">SGST</p>
                  {
                    <div className="pro-text-end">
                      <p className="pro-mb-0" style={{ minWidth: 140 }}>
                        ₹ {invoice?.gst?.SGST}
                      </p>
                    </div>
                  }
                </div>

                <div
                  className={`pro-d-flex pro-justify-end  pro-gap-3 pro-pt-1`}
                >
                  <p className="pro-mb-0 pro-font-xs pro-flex-shrink-0">CGST</p>
                  {
                    <div className="pro-text-end">
                      <p className="pro-mb-0" style={{ minWidth: 140 }}>
                        ₹ {invoice?.gst?.CGST}
                      </p>
                    </div>
                  }
                </div>
              </>
            )}

            <div className={`pro-d-flex pro-justify-end  pro-gap-3 pro-pt-1`}>
              <p className="pro-mb-0 pro-font-xs pro-flex-shrink-0">
                Handling Charge
              </p>
              {
                <div className="pro-text-end">
                  <p className="pro-mb-0" style={{ minWidth: 140 }}>
                    ₹ {invoice?.handling_charge}
                  </p>
                </div>
              }
            </div>

            <div className={`pro-d-flex pro-justify-end  pro-gap-3 pro-pt-1`}>
              <p className="pro-mb-0 pro-font-xs pro-flex-shrink-0">
                Round Off
              </p>
              {
                <div className="pro-text-end">
                  <p className="pro-mb-0" style={{ minWidth: 140 }}>
                    {invoice?.round_off?.type  + " ₹ " + invoice?.round_off?.amount}
                  </p>
                </div>
              }
            </div>
            <div className={`${Style.table_btm_content} pro-pb-1`}>
              <div className={`pro-d-flex pro-justify-end  pro-gap-3 pro-pt-3`}>
                <h6 className="pro-mb-0 pro-font-sm pro-flex-shrink-0">
                  Total Amount
                </h6>

                <h6
                  className="pro-mb-1"
                  style={{ minWidth: 140, textAlign: "end" }}
                >
                  ₹ {invoice?.total_amount}
                </h6>
              </div>
              <p
                className={`pro-mb-0 pro-text-uppercase pro-text-end ${Style.total}`}
              >
                {invoice?.total_amount_inwords}
              </p>
            </div>
          </div>
          <div className="pro-d-flex pro-justify-end pro-pt-5 pro-mx-5">
            {/* <button className="pro-btn pro-btn-secondary" onClick={handlePrintClick}>print</button>
            <button className="pro-btn pro-btn-secondary pro-ms-3" onClick={handleDownloadClick}>Download</button> */}

            <button
              onClick={handlePrintClick}
              className={`pro-btn-link dark pro-items-center pro-py-2 pro-px-2`}
            >
              <span className="material-symbols-outlined"> print </span>
              <span>
                <p className={`pro-fw-bold pro-mb-0 pro-ms-1`}>Print</p>
              </span>
            </button>

            <button
              onClick={handleDownloadClick}
              className={`pro-btn-link dark pro-items-center pro-py-2 pro-px-2 pro-ms-3`}
            >
              <span className="material-symbols-outlined">
                {" "}
                cloud_download{" "}
              </span>
              <span>
                <p className={`pro-fw-bold pro-mb-0 pro-ms-1`}>Download</p>
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Invoice;
