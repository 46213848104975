import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import { useEffect, useMemo } from "react";
import useRoutes from "../../../routes/useRoutes";
import { useDispatch } from "react-redux";
import {
  getBranchPermissions,
  getMenuPermissions,
  getOrderPermissions,
  updateConfig,
} from "../../../store/slices/Global";
const useCommonLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { menuDrawer } = useSelector((state) => state.global);

  const globalState = useSelector((state) => state.global);

  const {
    allowedDashboardComponent,
    allowedBranchComponents,
    allowedPaymentComponents,
    allowedConfigureComponents,
    allowedOrderComponents,
    allowedUserComponents,
  } = useRoutes();
  useEffect(() => {
    if (!globalState.isLogged && location.pathname === "/settings/appearance") {
      dispatch(
        updateConfig((state) => {
          state.isLogged = true;
        })
      );
    }

    //eslint-disable-next-line
  }, [dispatch, globalState.isLogged]);

  useEffect(() => {
    let sessionLogged = sessionStorage.getItem("USER_ACCESS_TOKEN");

    if (!sessionLogged) {
      sessionStorage.setItem(
        "USER_ACCESS_TOKEN",
        localStorage.getItem("USER_ACCESS_TOKEN")
      );

      dispatch(
        getMenuPermissions({ token: localStorage.getItem("USER_ACCESS_TOKEN") })
      );
      dispatch(getOrderPermissions());
      dispatch(getBranchPermissions());
    }

    //eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (
      globalState.isLogged === true &&
      (allowedDashboardComponent?.length > 0 ||
        allowedPaymentComponents?.length > 0 ||
        allowedBranchComponents?.length > 0 ||
        allowedConfigureComponents?.length > 0 ||
        allowedOrderComponents?.length > 0 ||
        allowedUserComponents?.length > 0)
    ) {
      // setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.isLogged = "idle";
        })
      );
      // }, 1800);

      if (allowedDashboardComponent?.length > 0) {
        navigate(
          `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedOrderComponents?.length > 0) {
        navigate(`/${allowedOrderComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedUserComponents?.length > 0) {
        navigate(`/${allowedUserComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedBranchComponents?.length > 0) {
        navigate(`/${allowedBranchComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedPaymentComponents?.length > 0) {
        navigate(
          `/${allowedPaymentComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedOrderComponents?.length > 0) {
        navigate(`/${allowedOrderComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedConfigureComponents?.length > 0) {
        navigate(
          `/configure/${
            allowedConfigureComponents?.map((item) => item?.path)?.[0]
          }`
        );
      }
    }

    //eslint-disable-next-line
  }, [
    allowedDashboardComponent,
    allowedPaymentComponents,
    allowedBranchComponents,
    allowedConfigureComponents,
    allowedOrderComponents,
    allowedUserComponents,
  ]);

  const { checkIfActiveRoute } = useRouteUtils();
  const navigations = [
    {
      label: "Dashboard",
      link: `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/dashboard"),
    },
    {
      label: "Order",
      link: `/${allowedOrderComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/orders`),
    },
    {
      label: "User",
      link: `/${allowedUserComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/user`),
    },
    {
      label: "Branch",
      link: `/${allowedBranchComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/branches`),
    },
    {
      label: "Payment",
      link: `/${allowedPaymentComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/payment`),
    },
    {
      label: "Configure",
      link: `/configure/${
        allowedConfigureComponents?.map((item) => item?.path)?.[0]
      }`,
      isButton: false,
      active: checkIfActiveRoute(`/configure`),
    },
  ];

  const handleGoBack = () => {
    navigate(menuDrawer?.backTo);
  };
  const topMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      menu.name,
      // "Dashboard",
    ]);

    return navigations
      .filter((menu) => menus?.includes?.(menu.label))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    navigations: topMenuPermission,
    menuDrawer,
    globalState,
    handleGoBack,
  };
};

export default useCommonLayout;
