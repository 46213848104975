import { getAxiosInstance } from "../../../../api";

export const getInvoiceData = async (orderId) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/admin/orders/invoice?order_id=${orderId}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
