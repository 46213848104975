import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAddNote } from "./api";
import { toast } from "react-toastify";
import { updateConfig } from "../../../../store/slices/orders/orderSlice";
import {
  orders,
  useGetOrderNoteHistoryQuery,
} from "../../../../store/queries/orders";

const useNoteForm = ({ refetch, closeModal, skip }) => {
  const dispatch = useDispatch();
  const { orderNoteId } = useSelector((state) => state.orders);
  const [note, setNote] = useState("");

  const { data: NoteHistory } = useGetOrderNoteHistoryQuery(
    {
      order_id: orderNoteId,
    },
    {
      skip: skip,
    }
  );

  const handleSubmitNote = () => {
    const obj = {
      note: note,
      order_id: orderNoteId,
    };

    handleAddNote(obj).then((res) => {
      if (res?.success === true) {
        toast.success("Note added ");
        dispatch(
          updateConfig((state) => {
            state.orderNoteId = "";
          })
        );
        refetch();
        closeModal();
        dispatch(orders.util.invalidateTags(["OrderNoteHistory"]));
      } else {
        toast.error("Failed to add note");
        dispatch(
          updateConfig((state) => {
            state.orderNoteId = "";
          })
        );
        closeModal();
      }
    });
  };

  return { note, NoteHistory, handleSubmitNote, setNote };
};

export default useNoteForm;
