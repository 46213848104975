import {
  Button,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useDeliveryForm from "./useDeliveryForm";
import {
  capitalizeOnSpace,
} from "../../../../utils/functions/table";

const DeliveryForm = ({ refetch, closeModal, isStickyFooter }) => {
  const {
    formik,
    selectedItemsDetails,
  } = useDeliveryForm({ refetch, closeModal });

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Name*"}
        type="text"
        id="name
          "
        name="name
          "
        className={`pro-input lg ${
          formik.errors.name && formik.touched.name && "error"
        }`}
        {...formik.getFieldProps("name")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.name && formik.touched.name}
        errorMessage={formik.errors.name}
      />

      {/* toggle button start  */}
      <div className="pro-toggle">
        <div className="pro-toggle-box">
          <input
            id="status"
            name="status"
            type="checkbox"
            checked={Boolean(formik.values?.status) ?? false}
            onChange={(e) => { formik.setFieldValue("status", !Boolean(formik.values?.status) ?? 0); }}
          />
          <span></span>
        </div>
        <label>Status</label>
      </div>
      {/* toggle button end  */}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default DeliveryForm;
