import React from "react";
import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import useContactUs from "./useContactUs";
import OrderColumn from "../../Global/OrderColumn";
import ReasonModal from "./ReasonModal";

const ContactUs = () => {
  const {
    contactUsData,
    contactUsState,
    activeFilter,
    filters,
    isLoading,
    actionOptions,
    isFetching,
    paginationOptions,
    showEditModal,
    statusModal,
    statusReason,
    errors,
    handleSubmitStatusChange,
    setStatusReason,
    closeStatusReasonModal,
    closeEditModal,
    refetch,
    updateTableFields,
    handlePageSize,
    handlePagination,
    getRow,
    handleSort,
    handleClearClick,
    handleActionChange,
    handleSearch,
    handleFilter,
  } = useContactUs();
  return (
    <>
      <HeadingGroup title={"Contact Us"} className={`pro-mb-4`} />

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col">
            <SearchFilters
              data={filters}
              activeFilter={activeFilter}
              handleButtonGroupChange={handleFilter}
              onSearchInput={handleSearch}
              showActions={false}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              actionOptions={actionOptions?.filter((item) => item.value === 0)}
              onActionOptionChange={handleActionChange}
              handleClear={handleClearClick}
              searchInputProps={{ value: contactUsState?.search }}
            />
          </div>
        </div>

        {contactUsData?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={contactUsData?.data?.data || []}
            uniqueID={"_id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            showCheckBox={false}
            deletable={false}
            clear={contactUsState.clearSelection}
            multiSelect={false}
            assignable={false}
            fields={contactUsData?.data?.fields}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={contactUsState?.currentPageSize}
          />
        )}

        {contactUsData?.data?.data?.length > 0 && (
          <Pagination
            currentPage={contactUsState.currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === contactUsState?.currentPageSize
            )}
            totalPageCount={Math.ceil(
              contactUsData?.data?.total_count / contactUsState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
      </div>

      <ModalLayout
        show={showEditModal}
        handleClose={closeEditModal}
        backdrop="static"
      >
        <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            refetch={refetch}
            tableFields={Object.keys(contactUsData?.data?.fields ?? {}).reduce(
              (filteredObj, property) => {
                filteredObj[property] = contactUsData?.data?.fields[property];
                return filteredObj;
              },
              {}
            )}
            moduleId={contactUsData?.data?.module_id}
            updateData={updateTableFields}
          />
        </div>
      </ModalLayout>

      <ModalLayout
        show={statusModal}
        handleClose={closeStatusReasonModal}
        backdrop="static"
      >
        <div className="pro-m-5">
          <ReasonModal
            setStatusReason={setStatusReason}
            statusReason={statusReason}
            handleSubmitStatusChange={handleSubmitStatusChange}
            closeModal={closeStatusReasonModal}
            errors={errors}
          />
        </div>
      </ModalLayout>
    </>
  );
};

export default ContactUs;
