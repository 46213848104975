import { useSelector } from "react-redux";
import { useMemo } from "react";

import Roles from "../components/Configure/roles";
import Dashboard from "../components/Dashboard";
import Product from "../components/Configure/product";
import Purpose from "../components/Configure/travelPurpose";
import Delivery from "../components/Configure/deliveryType";
import Staffs from "../components/Configure/staffs";
import Currency from "../components/Configure/currency";
import Branches from "../components/Branch/branches";
import Orders from "../components/Orders/orderListing";
import UserAdmin from "../components/Users/user";
import Payments from "../components/Payment/payments";
import ContactUs from "../components/Configure/ContactUs";
import Callback from "../components/Configure/Callback";
import GeneralEnquiry from "../components/Configure/generalEnquiry";

const useRoutes = () => {
  const globalState = useSelector((state) => state.global);

  const DashboardComponent = [
    {
      component: <Dashboard />,
      permission: "Dashboard",
      path: "dashboard",
    },
  ];

  const OrderComponents = [
    {
      component: <Orders />,
      permission: "Orders",
      path: "orders",
    },
  ];

  const UserComponents = [
    {
      component: <UserAdmin />,
      permission: "Users List",
      path: "user",
    },
  ];

  const BranchComponents = [
    {
      component: <Branches />,
      permission: "Branches",
      path: "branches",
    },
  ];

  const PaymentComponents = [
    {
      component: <Payments />,
      permission: "Payments",
      path: "payment",
    },
  ];

  const ConfigureComponents = [
    {
      component: <Roles />,
      permission: "Roles",
      path: "role",
    },
    {
      component: <Purpose />,
      permission: "Travel Purpose",
      path: "travel-purpose",
    },
    {
      component: <Delivery />,
      permission: "Delivery Type",
      path: "delivery-type",
    },
    {
      component: <Product />,
      permission: "Products",
      path: "product",
    },
    {
      component: <Currency />,
      permission: "Currencies",
      path: "currency",
    },
    {
      component: <Staffs />,
      permission: "Staffs",
      path: "staffs",
    },
    {
      component: <Callback />,
      permission: "Callback",
      path: "callback",
    },
    {
      component: <GeneralEnquiry />,
      permission: "General Enquiry",
      path: "general-enquiry",
    },
    {
      component: <ContactUs />,
      permission: "Contact Us",
      path: "contact-us",
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState?.dashboard_permissions?.flatMap?.((menu) => [
      ...menu.permissions?.map((side_menu) => side_menu.menu_name),
    ]);
    if (!menus) {
      menus = [];
    }
    menus = [
      ...menus,
      "Appearance",
      // "Dashboard"
    ];
    return menus;

    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  function hasPermission(permission) {
    const user = {
      permissions: drawerMenuPermission,
    };
    return user.permissions?.includes(permission);
  }

  const allowedDashboardComponent = DashboardComponent.filter(
    ({ permission }) => hasPermission(permission)
  );
  const allowedOrderComponents = OrderComponents.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedUserComponents = UserComponents.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedBranchComponents = BranchComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedPaymentComponents = PaymentComponents.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedConfigureComponents = ConfigureComponents.filter(
    ({ permission }) => hasPermission(permission)
  );

  return {
    allowedDashboardComponent,
    allowedBranchComponents,
    allowedPaymentComponents,
    allowedConfigureComponents,
    allowedOrderComponents,
    allowedUserComponents,
  };
};

export default useRoutes;
