import { getAxiosInstance } from "../../../api";

export const CreateBankAccount = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/bank-account/create", body);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const viewBankAccountDetails = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `admin/bank-account/view?bank_account_id=${params.bank_account_id}`
    );

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const UpdateBankAccount = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/bank-account/update", body);

    return response;
  } catch (error) {
    return error.response.data;
  }
};
