import { useParams } from "react-router-dom";
import { useGetDeliveryDetailsQuery } from "../../../../store/queries/orders";

const useDeliveryDetails = () => {
  const { ID } = useParams();

  const { data: mainData, isFetching } = useGetDeliveryDetailsQuery({
    order_id: ID ?? localStorage.getItem("product_order_id"),
  });

  const deliveryDetailLabel = {
    delivery_type: "Delivery Type",
    pincode: "Pin Code",
    address: "Address",
    district: "District",
    state: "State",
    country: "Country",
  };

  const deliveryDetailValue = {
    delivery_type: `${
      mainData?.data?.delivery_type?.length > 0
        ? mainData?.data?.delivery_type
        : "Not specified"
    }`,
    address: `${
      mainData?.data?.address?.length > 0
        ? mainData?.data?.address
        : "Not specified"
    }`,
    district: `${
      mainData?.data?.district?.length > 0
        ? mainData?.data?.district
        : "Not specified"
    }`,
    state: `${
      mainData?.data?.state?.length > 0
        ? mainData?.data?.state
        : "Not specified"
    }`,
    country: `${
      mainData?.data?.country?.length > 0
        ? mainData?.data?.country
        : "Not specified"
    }`,
  };

  if (mainData?.data?.delivery_type !== "Pick Up Delivery") {
    deliveryDetailValue.pincode = `${
      mainData?.data?.pincode
        ? mainData?.data?.pincode
        : "Not specified"
    }`;
  }

  const deliveryDetails = Object.keys(deliveryDetailValue).map((key) => {
    return {
      label: deliveryDetailLabel[key],
      value:
        typeof deliveryDetailValue[key] === "string"
          ? deliveryDetailValue?.[key]
          : typeof deliveryDetailValue[key] === "number"
          ? deliveryDetailValue?.[key]
          : deliveryDetailValue?.[key]?.name,
    };
  });

  return {
    deliveryDetails,
    isFetching,
  };
};

export default useDeliveryDetails;
