import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { documentVerification } from "../../../../store/slices/orders/orderSlice";
import { useGetDocumentListeDataQuery } from "../../../../store/queries/orders";
import { toast } from "react-toastify";
import { generatePdfThumbnail } from "../../../../utils/functions/getPdfUrl";
import moment from "moment";

const useDocumentDetails = () => {
  const dispatch = useDispatch();
  const { ID } = useParams();

  const [itemRejected, setItemRejected] = useState(false);
  const [note, setNote] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");
  const [docId, setDocId] = useState("");
  const [itemStatus, setItemStatus] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);

  const {
    data: mainData,
    isFetching,
    refetch,
  } = useGetDocumentListeDataQuery({
    order_id: ID ?? localStorage.getItem("product_order_id"),
  });

  const handleViewImage = (item) => {
    setItemRejected(false);
    setDocumentType(item?.document_type);
    setDocId(item?.id);
    setRejectReason(item?.reject_reason);
    setShowImageModal(() => true);
    setImageData(item?.name);
    setItemStatus(item?.status);
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
    setDocId("");
    setDocumentType("");
  };

  const handleVerification = (id, status, note) => {
    let formData = {
      document_id: id,
      status: status,
      note: note,
      _method: "PUT",
    };
    dispatch(documentVerification(formData))
      .unwrap()
      .then((result) => {
        if (result?.success) {
          toast.success(result?.message);
        } else {
          toast.error(result?.message);
        }
        if (result) {
          refetch();
          setShowImageModal(() => false);
          setImageData("");
          setDocId("");
          setDocumentType("");
        }
      })
      .catch((err) => {
        toast.error("Failed to verify document");
      });
  };

  const handleDownloadPdf = async (url) => {
    setDownloadLoading(true);
    const parts = url?.split("/");
    const fileName = parts[parts.length - 1];

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setDownloadLoading(false);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `${moment(Date.now()).format(
        "MM_DD_YYYY_HH_mm_ss"
      )}_${fileName}`;
      document.body.appendChild(link);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("There was an error downloading the file:", error);
    }
  };

  return {
    mainData,
    showImageModal,
    imageData,
    docId,
    itemStatus,
    isFetching,
    itemRejected,
    rejectReason,
    note,
    documentType,
    downloadLoading,
    handleDownloadPdf,
    generatePdfThumbnail,
    handleViewImage,
    closeImageModal,
    setItemRejected,
    handleVerification,
    setNote,
  };
};

export default useDocumentDetails;
