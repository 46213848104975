import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  emailRegExp,
  phoneRegExp,
} from "../../../../utils/functions/table";

import { useSelector } from "react-redux";
import {
  updateConfig,
  createNewEntry,
  updateData,
} from "../../../../store/slices/Staffs/staffsSlice.js";
import countryData from "../../../../utils/components/countryCode";

const useBranchForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const profilefileInputRef = useRef(null);

  const { selectedItemsDetails, is_edit, selectedId } = useSelector((state) => state.staffs);


  const [imageCoverPreview, setImageCoverPreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.profile_image : ""
  );
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (selectedItemsDetails !== "") {
      setImageCoverPreview(selectedItemsDetails?.profile_image ?? "");
    }
  }, [selectedItemsDetails]);



  const validation = Yup.object({
    name: Yup.string()
      .min(2, "The first name must be at least 2 characters")
      .max(20, "max 20 characters allowed")
      .matches(/^[a-zA-Z\s]+$/, "Invalid name Format")
      .required("*name  is required"),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(7, "The phone number must be between 7 and 14 digits")
      .max(14, "The phone number must be between 7 and 14 digits")
      .required("*phone number is required"),
    email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email")
      .required("*email is required"),
    country_code: Yup.string().required("*country code is required"),
    gender: Yup.mixed()
      .required("*gender detail is required"),
    branch_id: Yup.mixed()
      .required("*branch detail is required"),
    role_id: Yup.mixed()
      .required("*role detail is required"),


  });


  const formik = useFormik({
    initialValues: {
      name:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.name
          : "",
      country_code:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.country_code
          : "+91",
      phone_number:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.phone_number
          : "",
      email:
        selectedItemsDetails !== "" ? selectedItemsDetails?.email : "",
      gender: selectedItemsDetails !== "" ?
        selectedItemsDetails?.gender : "",
      branch_id: selectedItemsDetails !== "" ?
        selectedItemsDetails?.branch_details : "",
      role_id: selectedItemsDetails !== "" ?
        selectedItemsDetails?.role_details : "",



    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setSubmitLoading(true)
      let obj = {
        name: values.name,
        phone_number: values.phone_number,
        country_code: values.country_code,
        email: values.email,
        gender: values?.gender?.id,
        branch_id: values?.branch_id?._id ? values?.branch_id?._id : values?.branch_id?.id,
        role_id: values?.role_id?._id ? values?.role_id?._id : values?.role_id?.id,

      };
      if (is_edit) {
        obj.staff_id = selectedId ? selectedId : ""
      }

      if (values.profile_image?.name) {
        obj.profile_image = values.profile_image;
      }

      let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if (selectedItemsDetails !== "") {
        formData.append("_method", "PUT");
        
        dispatch(
          updateData(formData)
        ).then((response) => {
          setSubmitLoading(false);
          if (response?.payload?.success) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            toast.success(response?.payload?.message);
          } else if (!response?.payload?.status) {
            formik.setErrors(response?.payload?.data);
          } else toast.error(response?.payload?.message);
        });
      } else {
        dispatch(createNewEntry(formData)).then((response) => {
          setSubmitLoading(false);
          if (response?.payload?.success) {
            resetForm();
            refetch();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );

            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            formik.setErrors(response?.payload?.data);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });

  const countryCodeOptions = useMemo(
    () =>
      countryData?.map((opt) => {
        return {
          value: opt.value,
          label: opt.label,
          title: `${opt.name}(${opt.code}) ${" "}  ${opt.label}`,
          code: opt.code,
        };
      }),
    []
  );

  const handleCountryId = (value) => {
    formik.setFieldValue("country_id", value.id);
  };

  const handlePhoneNumberChange = (value) => {
    if (value !== formik.values.mobile_number) {
      dispatch(
        updateConfig((state) => {
          state.phoneVerified = {
            verified: false,
            status: false,
          };
        })
      );
    }
    formik.setFieldValue("mobile_number", value);
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleCoverImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("profile_image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageCoverPreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };


  return {
    formik,
    imageCoverPreview,
    selectedItemsDetails,
    profilefileInputRef,
    handleCoverImage,
    handleCloseModal,
    countryCodeOptions,
    handlePhoneNumberChange,
    handleCountryId,
    submitLoading
  };
};

export default useBranchForm;
