import { getAxiosInstance } from "../../../api";

export const exportOrderReport = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/orders/exporrr`, {
      params: body,
      responseType: "blob",
    });
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updatePaymentStatus = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/admin/orders/payment-status/change`,
      body
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
