import React from "react";
import Style from "./addStaffForm.module.scss";
// import Select from "react-select";
import AdminProfileCard from "../../../Global/AdminProfileCard";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import countryData from "../../../../utils/components/countryCode";

const AddFormFields = ({
  formikEditUserData,
  formikEditPassword,
  profilefileInputRef,
  handleProfileImage,
  showCurrentPassword,
  imagePreview,
  userData,
  setProfileViewVisible,
  showNewPassword,
  handleShowCurrentPassword,
  handleShowNewPassword,
  handleShowConfirmPassword,
  showConfirmPassword,
  basicData,
}) => {
  return (
    <div className={Style.root}>
      <div className={Style.item}>
        <AdminProfileCard
          userData={userData}
          formikEditUserData={formikEditUserData}
          profilefileInputRef={profilefileInputRef}
          imagePreview={imagePreview}
          handleProfileImage={handleProfileImage}
        />
      </div>
      <div className={Style.item}>
        <h5 className="pro-ttl h5">Profile info</h5>

        <div className="input-wrap pro-mb-4">
          <label htmlFor="name" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Name
          </label>
          <input
            type="name"
            className={`pro-input lg ${
              formikEditUserData.errors.name &&
              formikEditUserData.touched.name &&
              "error"
            }`}
            id="name"
            name="name"
            {...formikEditUserData.getFieldProps("name")}
          />
          {formikEditUserData.touched.name &&
            formikEditUserData.errors.name && (
              <span className="error-text">
                {formikEditUserData.errors.name}
              </span>
            )}
        </div>

        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Gender
          </label>
          <Select
            id="gender"
            name="gender"
            placeholder={"Select"}
            className={`pro-input lg  ${
              formikEditUserData?.errors?.gender && " error"
            }`}
            classNamePrefix="pro-input"
            options={basicData?.data?.gender}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formikEditUserData?.values?.gender}
            onBlur={formikEditUserData.handleBlur?.gender}
            onChange={(value) =>
              formikEditUserData?.setFieldValue("gender", value || null)
            }
            menuPlacement="auto"
          />
          {formikEditUserData?.errors?.gender && (
            <span className="error-text">
              {formikEditUserData?.errors?.gender}
            </span>
          )}
        </div>

        <div className="input-wrap pro-mb-4">
          <label htmlFor="email" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Email
          </label>
          <input
            type="email"
            className={`pro-input lg ${
              formikEditUserData.errors.email &&
              formikEditUserData.touched.email &&
              "error"
            }`}
            id="email"
            name="email"
            {...formikEditUserData.getFieldProps("email")}
          />
          {formikEditUserData.touched.email &&
            formikEditUserData.errors.email && (
              <span className="error-text">
                {formikEditUserData.errors.email}
              </span>
            )}
        </div>

        <div className="input-wrap pro-mb-4">
          <div className="input-country-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Phone number*
            </label>
            <div className="input-main-wrap ">
              <div className="code-wrap pro-mb-4 ">
                <Select
                  id="country_code"
                  options={countryData}
                  // getOptionLabel={(option) => `${option.label}`}
                  getOptionValue={(option) => `${option.value}`}
                  // onMenuOpen={handleMenuOpen}
                  // onMenuClose={handleMenuClose}
                  // menuIsOpen={menuIsOpen}
                  // formatOptionLabel={({ label, title }) =>
                  //   menuIsOpen ? title : label
                  // }
                  className={` multi-select country-select ${
                    formikEditUserData.touched.country_code &&
                    formikEditUserData.errors.country_code &&
                    " error"
                  }`}
                  value={countryData?.filter(
                    (p) =>
                      p.value?.toString() ===
                      formikEditUserData.values.country_code?.toString()
                  )}
                  classNamePrefix="pro-input"
                  onBlur={() => formikEditUserData.handleBlur("country_code")}
                  onChange={(value) =>
                    formikEditUserData.setFieldValue(
                      "country_code",
                      value?.value
                    )
                  }
                ></Select>
              </div>
              <input
                type="text"
                id="phone_number"
                name="phone_number"
                className={`pro-input lg ${
                  formikEditUserData.errors.phone_number &&
                  formikEditUserData.touched.phone_number &&
                  " error"
                }`}
                {...formikEditUserData.getFieldProps("phone_number")}
                error={
                  formikEditUserData.errors.phone_number &&
                  formikEditUserData.touched.phone_number
                }
                errorMessage={formikEditUserData.errors.phone_number}
                // onBlur={() => formikEditUserData.handleBlur("phone_number")}
                // onChange={(e) => handlephone_numberChange(e.target.value)}
                // {...formikEditUserData.getFieldProps("phone_number")}
              />
            </div>
            {formikEditUserData.touched.phone_number &&
              formikEditUserData.errors.phone_number && (
                <span className="error-text">
                  {formikEditUserData.errors.phone_number}
                </span>
              )}
          </div>
        </div>

        <div className={`col-12 pro-mt-4 pro-d-flex pro-justify-end`}>
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => setProfileViewVisible(true)}
          >
            Cancel
          </Button>

          <Button
            className={"pro-btn-primary lg pro-mx-3"}
            onClick={formikEditUserData.handleSubmit}
            type="submit"
          >
            {true ? (
              <i
                className="spinner-border spinner-border-sm me-3"
                role="status"
                aria-hidden="true"
              ></i>
            ) : (
              ""
            )}
            Update
          </Button>
        </div>
      </div>

      <div className={Style.item}>
        <h5 className="pro-ttl h5">Change Password</h5>

        <div className="input-wrap p-re pro-mb-4">
          <label
            htmlFor="current_password"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Current Password
          </label>
          <input
            type="password"
            className={`pro-input lg ${
              formikEditPassword.errors.current_password &&
              formikEditPassword.touched.current_password &&
              "error"
            }`}
            id="current_password"
            name="current_password"
            {...formikEditPassword.getFieldProps("current_password")}
          />
          {showCurrentPassword ? (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowCurrentPassword(e)}
            >
              <span className="material-symbols-outlined">
                {" "}
                visibility_off{" "}
              </span>
            </button>
          ) : (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowCurrentPassword(e)}
            >
              <span className="material-symbols-outlined"> visibility </span>
            </button>
          )}
          {formikEditPassword.touched.current_password &&
            formikEditPassword.errors.current_password && (
              <span className="error-text">
                {formikEditPassword.errors.current_password}
              </span>
            )}
        </div>

        <div className="input-wrap p-re pro-mb-4">
          <label
            htmlFor="new_password"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            New password
          </label>
          <input
            type="password"
            className={`pro-input lg ${
              formikEditPassword.errors.new_password &&
              formikEditPassword.touched.new_password &&
              "error"
            }`}
            id="new_password"
            name="new_password"
            {...formikEditPassword.getFieldProps("new_password")}
          />
          {showNewPassword ? (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowNewPassword(e)}
            >
              <span className="material-symbols-outlined">
                {" "}
                visibility_off{" "}
              </span>
            </button>
          ) : (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowNewPassword(e)}
            >
              <span className="material-symbols-outlined">visibility</span>
            </button>
          )}
          {formikEditPassword.touched.new_password &&
            formikEditPassword.errors.new_password && (
              <span className="error-text">
                {formikEditPassword.errors.new_password}
              </span>
            )}
        </div>

        <div className="input-wrap p-re">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Confirm password
          </label>
          <input
            type="password"
            className={`pro-input lg ${
              formikEditPassword.errors.confirm_password &&
              formikEditPassword.touched.confirm_password &&
              "error"
            }`}
            id="confirm_password"
            name="confirm_password"
            {...formikEditPassword.getFieldProps("confirm_password")}
          />

          {showConfirmPassword ? (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowConfirmPassword(e)}
            >
              <span className="material-symbols-outlined">
                {" "}
                visibility_off{" "}
              </span>
            </button>
          ) : (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowConfirmPassword(e)}
            >
              <span className="material-symbols-outlined"> visibility </span>
            </button>
          )}
          {formikEditPassword.touched.confirm_password &&
            formikEditPassword.errors.confirm_password && (
              <span className="error-text">
                {formikEditPassword.errors.confirm_password}
              </span>
            )}
        </div>
      </div>
    </div>
  );
  //  : isDataLoading === "loading" ? (
  //   <div>Loading...</div>
  // ) : (
  //   ""
  // );
};

export default AddFormFields;
