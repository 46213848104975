import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
  ConfirmationBox,
  DataContainer,
  SimpleReport,
} from "@wac-ui-dashboard/wac_component_library";
import useOrders from "./useOrders";
import EmptyData from "../../Global/EmptyData";
import Style from "./orders.module.scss";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";
import Invoice from "./Invoice";
import { useEffect, useState } from "react";
import DashboardHero from "../../Dashboard/DashboardHero";
import Select from "react-select";
import ReasonModal from "./ReasonModal";
import NoteForm from "./NoteForm";
import SimpleReportShimmer from "../../Global/Shimmers/SimpleReportShimmer";

const Orders = ({ dashboard = false, mainFilter = "", subFilter = "" }) => {
  const {
    mainData,
    handleDateChange,
    handleEditClick,
    isLoading,
    actionOptions,
    handleClearClick,
    currentPage,
    paginationOptions,
    menuState,
    isFetching,
    filters,
    date,
    activeFilter,
    showEditModal,
    newData,
    showInvoiceModal,
    OrderItems,
    basicData,
    orderPaymentReason,
    errors,
    statusReason,
    showConfirmationModal,
    skip,
    isPaymentSummaryFetching,
    paymentReportDetails,
    handleCloseNoteForm,
    handleSubmitOrderComplete,
    closeConfirmationModal,
    handleMainStatusFilter,
    handleSubStatusFilter,
    setStatusReason,
    handleSubmitPaymentReviewChange,
    closePaymentReasonModal,
    handleFilter,
    handleSearch,
    handleSort,
    updateTableFields,
    refetch,
    closeEditModal,
    handlePagination,
    getRow,
    handleActionChange,
    handlePageSize,
    handleDateRangeChange,
    handleDashboardRedirect,
    closeInvoiceModal,
    handleBranchFilter,
  } = useOrders({ dashboard, mainFilter, subFilter });

  const [totalPageCount, setTotalPageCount] = useState(0);

  useEffect(() => {
    if (mainData?.data?.total_count) {
      const pageCount = Math.ceil(
        mainData?.data?.total_count / menuState?.currentPageSize
      );
      setTotalPageCount(pageCount);
    }
    //eslint-disable-next-line
  }, [mainData?.data]);

  return (
    <>
      {!dashboard && (
        <>
          <HeadingGroup title={"Orders"} className={`pro-mb-4`} />

          <div className={`pro-w-100 pro-my-5`}>
            <DashboardHero
              data={mainData?.data?.order_count_details}
              obj={OrderItems}
              transactions={true}
              loading={isFetching}
              shimmerCount={6}
              cardsPerLine={4}
              isOrder={true}
            />
          </div>
        </>
      )}

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col">
            {!dashboard && (
              <SearchFilters
                data={filters}
                activeFilter={activeFilter}
                handleButtonGroupChange={handleFilter}
                dropDownFilter={
                  <>
                    <div className="col-auto">
                      <CustomDateRangePicker
                        defaultDateRange={date}
                        handleDateRangeChange={handleDateRangeChange}
                      />
                    </div>

                    <div className="col-auto">
                      <Select
                        id="main_status"
                        isClearable={false}
                        placeholder={"Select "}
                        options={basicData?.data?.order_list_main_status}
                        getOptionValue={(option) => option?.id}
                        getOptionLabel={(option) => option?.name}
                        className={`pro-input lg multi-select`}
                        classNamePrefix="pro-input"
                        value={basicData?.data?.order_list_main_status?.filter(
                          (p) => p?.id === menuState?.currentMainStatusFilter
                        )}
                        onChange={(value) => handleMainStatusFilter(value)}
                      />
                    </div>
                    <div className="col-auto">
                      <Select
                        id="sub_status"
                        isClearable={true}
                        placeholder={"Select  Status"}
                        options={basicData?.data?.order_list_sub_status}
                        getOptionValue={(option) => option?.id}
                        getOptionLabel={(option) => option?.name}
                        className={`pro-input lg multi-select`}
                        classNamePrefix="pro-input"
                        value={basicData?.data?.order_list_sub_status?.filter(
                          (p) => menuState?.currentSubStatusFilter === p?.id
                        )}
                        onChange={(value) => handleSubStatusFilter(value)}
                      />
                    </div>

                    <div className="col-auto">
                      <Select
                        id="branch"
                        isMulti
                        isClearable={true}
                        placeholder={"Select Branch"}
                        options={basicData?.data?.branches}
                        getOptionValue={(option) => option?._id}
                        getOptionLabel={(option) => option?.name}
                        className={`pro-input lg multi-select`}
                        classNamePrefix="pro-input"
                        value={basicData?.data?.branches?.filter((p) =>
                          menuState?.currentBranchFilter?.includes(p._id)
                        )}
                        onChange={(value) =>
                          handleBranchFilter(value.map((v) => v._id))
                        }
                      />
                    </div>
                  </>
                }
                initialDateRange={date}
                onDateChange={handleDateChange}
                onSearchInput={handleSearch}
                showActions={false}
                handleActionClick={handleEditClick}
                loading={isLoading}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                actionOptions={
                  !dashboard ? actionOptions?.map((item) => item) : ""
                }
                onActionOptionChange={handleActionChange}
                handleClear={handleClearClick}
                searchInputProps={{ value: menuState?.search }}
              />
            )}
          </div>
        </div>

        {mainData?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <div
            onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
            className={!dashboard ? "pro-pt-3" : `${Style.dashboardTable}`}
          >
            <Table
              data={mainData?.data?.data || []}
              uniqueID={"order_id"}
              deletable={false}
              clear={menuState?.clearSelection}
              multiSelect={false}
              assignable={false}
              fields={newData}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={menuState?.currentPageSize}
            />
          </div>
        )}

        {mainData?.data?.data?.length > 0 && !dashboard && (
          <div className="pro-my-3">
            <DataContainer>
              {isPaymentSummaryFetching ? (
                <SimpleReportShimmer />
              ) : (
                <SimpleReport
                  data={paymentReportDetails}
                  propStyle={{ item: Style.report_root }}
                />
              )}
            </DataContainer>
          </div>
        )}

        {mainData?.data?.data?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={totalPageCount}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(mainData?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = mainData?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={mainData?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={orderPaymentReason}
          handleClose={closePaymentReasonModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ReasonModal
              setStatusReason={setStatusReason}
              statusReason={statusReason}
              handleSubmitPaymentReviewChange={handleSubmitPaymentReviewChange}
              closeModal={closePaymentReasonModal}
              errors={errors}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showInvoiceModal}
          handleClose={closeInvoiceModal}
          centered={false}
        >
          <Invoice />
        </ModalLayout>

        <ModalLayout
          show={showConfirmationModal}
          handleClose={closeConfirmationModal}
          backdrop="static"
          centered={true}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to complete this order?`}
              subTitle={`This action cannot be undone.`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={closeConfirmationModal}
              submitAction={handleSubmitOrderComplete}
            />
          </div>
        </ModalLayout>

        <OffCanvasLayout
          centered={true}
          show={menuState?.showNoteForm}
          handleClose={handleCloseNoteForm}
          title={"Note"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <NoteForm
            closeModal={handleCloseNoteForm}
            refetch={refetch}
            skip={skip}
          />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default Orders;
