import style from "./dashboardCard.module.scss";
import { Tooltip as ReactTooltip } from "react-tooltip";

const DashboardCard = ({
  title,
  data,
  prev,
  icon,
  active,
  transactions,
  dualTotal,
  totalValue,
  isOrder,
}) => {
  return (
    <div
      className={`${style.root} ${active ? `active` : ""} ${
        isOrder ? style.order_card : ""
      }`}
    >
      <div className={`${style.inner}`}>
        <div className={`${style.inner_heading} pro-mb-3`}>
          <div className={`${style.icon}`}>
            <span className="material-symbols-outlined">{icon}</span>
          </div>
          <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium pro-ps-2">
            {data?.month ? `${title}(${data?.month}) ` : title}
          </h6>
        </div>
        <div className={`${style.body} pro-d-flex`}>
          {/* <h3 className="pro-ttl h3 pro-mb-2 pro-fw-medium">
            {
                (data?.total !== null && data?.total !== undefined)
                  ? data?.total
                  : ((data !== null && data !== undefined)
                    ? data
                    : null)
              }
          </h3> */}

          <h3
            className={`pro-ttl pro-mb-0 pro-fw-bold ${style.total_value}`}
            data-tooltip-id={dualTotal ? `total-value` : ""}
          >
            <span className={style.primary_value}>
              {data?.total !== null && data?.total !== undefined
                ? data?.total
                : data !== null && data !== undefined
                ? data
                : null}
            </span>
          </h3>
          {dualTotal && (
            <ReactTooltip
              id={`total-value`}
              place="right"
              content={`₹ ${totalValue}`}
              className={"react-tooltip"}
            />
          )}
          {data?.growth_percentage !== null && (
            <span
              className={`${
                data?.growth_type === 1
                  ? style.increment
                  : data?.growth_type === 2
                  ? style.decrement
                  : style.neutral
              } ${
                style.growth_percentage
              } pro-align-self-center pro-font-xs pro-fw-medium`}
            >
              {data?.growth_percentage}
            </span>
          )}
        </div>

        {transactions &&
        data?.growth_percentage !== null &&
        prev !== undefined ? (
          <div
            className={`${style.footer} pro-d-flex pro-badge pro-mt-4 badge-danger pro-me-auto pro-items-center`}
          >
            <h6
              className={`pro-ttl pro-mb-0 pro-fw-bold pro-font-xs ${style.last_month}`}
            >
              {`${prev} :`}
            </h6>

            <span
              className={`${style.growth_percentage} pro-align-self-center pro-font-xs pro-fw-medium pro-ps-1`}
            >
              {typeof data?.previous_collection === "string" ? (
                <span class="material-symbols-outlined">currency_rupee</span>
              ) : (
                <></>
              )}
              {data?.previous_collection}
            </span>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default DashboardCard;
