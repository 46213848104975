import {
	Button,
	IconText,
	Image,
	Input,
} from "@wac-ui-dashboard/wac_component_library";
import React, { useState } from "react";
import Select from "react-select";
import Style from "./staffsForm.module.scss";
import useStaffsForm from "./useStaffsForm";
import {
	capitalizeOnSpace,
	limitStrLength,
} from "../../../../utils/functions/table";

const StaffsForm = ({
	refetch,
	optionsData,
	hasUpdatePermission,
	closeModal,
	isStickyFooter,
	formData
}) => {
	const {
		formik,
		imageCoverPreview,
		handleCoverImage,
		selectedItemsDetails,
		profilefileInputRef,
		countryCodeOptions,
		submitLoading
	} = useStaffsForm({ refetch, closeModal });

	const [menuIsOpen, setMenuIsOpen] = useState(false);

	const handleMenuOpen = () => {
		setMenuIsOpen(() => true);
	};

	const handleMenuClose = () => {
		setMenuIsOpen(() => false);
	};

	return (
		<div className={`pro-w-100`}>
			<Input
				label={"Name*"}
				type="text"
				id="name
          "
				name="name
          "
				className={`pro-input lg ${formik.errors.name && formik.touched.name && "error"
					}`}
				{...formik.getFieldProps("name")}
				onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
				error={formik.errors.name && formik.touched.name}
				errorMessage={formik.errors.name}
			/>

			<div className="col pro-mb-4">
				<div className="input-wrap">
					<label
						htmlFor="gender"
						className="pro-font-sm pro-mb-1 pro-fw-medium"
					>
						Gender*
					</label>
					<Select
                         id="gender"
                         name="gender"
                         placeholder={"Select"}
                         className={`pro-input lg  ${formik?.errors?.gender && formik.touched.gender && " error"}`}
                         classNamePrefix="pro-input"
                         options={formData?.gender}
                         getOptionLabel={(option) => option?.name}
                         getOptionValue={(option) => option?.id}
                         value={formik?.values?.gender}
                         onBlur={formik.handleBlur("gender")}
                         onChange={(value) =>
                              formik?.setFieldValue("gender", value || null)
                         }
                         menuPlacement="auto"
                    />
					{formik.touched.gender && formik.errors.gender && (
						<div className="error-text">{formik.errors.gender}</div>
					)}
				</div>
			</div>

			<Input
				label={"Email address*"}
				type="text"
				id="email"
				name="email"
				placeholder="name@example.com"
				className={`pro-input lg ${formik.errors.email && formik.touched.email && " error"
					}`}
				{...formik.getFieldProps("email")}
				error={formik.errors.email && formik.touched.email}
				errorMessage={formik.errors.email}
			/>
			<div className="col pro-mb-4">
				<div className="input-wrap">
					<label
						htmlFor="branch_id"
						className="pro-font-sm pro-mb-1 pro-fw-medium"
					>
						Branch*
					</label>
					<Select
                         id="branch_id"
                         name="branch_id"
                         placeholder={"Select"}
                         className={`pro-input lg  ${formik?.errors?.branch_id && formik.touched.branch_id && " error"}`}
                         classNamePrefix="pro-input"
                         options={formData?.branches}
                         getOptionLabel={(option) => option?.name}
                         getOptionValue={(option) => option?._id}
                         value={formik?.values?.branch_id}
                         onBlur={formik.handleBlur("branch_id")}
                         onChange={(value) =>
                              formik?.setFieldValue("branch_id", value || null)
                         }
                         menuPlacement="auto"
                    />


					{formik.touched.branch_id && formik.errors.branch_id && (
						<div className="error-text">{formik.errors.branch_id}</div>
					)}
				</div>
			</div>
			<div className="col pro-mb-4">
				<div className="input-wrap">
					<label
						htmlFor="role_id"
						className="pro-font-sm pro-mb-1 pro-fw-medium"
					>
						Role*
					</label>
					<Select
                         id="role_id"
                         name="role_id"
                         placeholder={"Select"}
                         className={`pro-input lg  ${formik?.errors?.role_id && formik.touched.role_id && " error"}`}
                         classNamePrefix="pro-input"
                         options={formData?.roles}
                         getOptionLabel={(option) => option?.name}
                         getOptionValue={(option) => option?._id}
                         value={formik?.values?.role_id}
                         onBlur={formik.handleBlur("role_id")}
                         onChange={(value) =>
                              formik?.setFieldValue("role_id", value || null)
                         }
                         menuPlacement="auto"
                    />
					{formik.touched.role_id && formik.errors.role_id && (
						<div className="error-text">{formik.errors.role_id}</div>
					)}
				</div>
			</div>

			<div className="input-wrap pro-mb-4">
				<div className="input-country-wrap">
					<label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
						Country Code*
					</label>
						<Select
							id="country_code"
							options={countryCodeOptions}
							getOptionValue={(option) => `${option.value}`}
							onMenuOpen={handleMenuOpen}
							onMenuClose={handleMenuClose}
							menuIsOpen={menuIsOpen}
							formatOptionLabel={({ label, title }) =>
								menuIsOpen ? title : label
							}
							className={` pro-input lg ${formik.touched.country_code &&
								formik.errors.country_code &&
								" error"
								}`}
							value={countryCodeOptions?.filter(
								(p) =>
									p.value?.toString() === formik.values.country_code?.toString()
							)}
							classNamePrefix="pro-input"
							onBlur={() => formik.handleBlur("country_code")}
							onChange={(value) =>
								formik.setFieldValue("country_code", value?.value)
							}
						/>
						
					
				</div>
				{formik.touched.country_code && formik.errors.country_code && (
						<div className="error-text">{formik.errors.country_code}</div>
					)}
			</div>
			
			<Input
				label={"Phone Number*"}
				type="text"
				id="phone_number"
				name="phone_number"
				// placeholder="name@example.com"
				className={`pro-input lg ${formik.errors.phone_number &&
					formik.touched.phone_number &&
					" error"
					}`}
				{...formik.getFieldProps("phone_number")}
				error={
					formik.errors.phone_number && formik.touched.phone_number
				}
				errorMessage={formik.errors.phone_number}
				onBlur={() => formik.handleBlur("phone_number")}
				onChange={(event) => {
					// Remove any non-digit characters from the input
					const numericValue = event.target.value.replace(/\D/g, "");
					// Update the formik value with the numeric value
					formik.setFieldValue("phone_number", numericValue);
				  }}
				value={formik.values.phone_number}
			/>
			

			<div className="input-wrap pro-mb-4">
				<label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
					Profile Image
				</label>
				<div className="input-drag">
					<input
						type="file"
						placeholder="placeholder"
						accept=".jpeg, .jpg , .png"
						ref={profilefileInputRef}
						className={`pro-input ${formik.touched.profile_image && formik.errors.profile_image && " error"
							}`}
						id="profile_image"
						name="profile_image"
						onBlur={formik.handleBlur("profile_image")}
						onChange={(e) => handleCoverImage(e)}
					/>
					<span className="input-drag-box">
						<IconText
							title={
								formik?.values?.profile_image?.name !== undefined
									? limitStrLength(formik?.values?.profile_image?.name, 30)
									: selectedItemsDetails !== "" &&
										selectedItemsDetails?.profile_image_name
										? selectedItemsDetails?.profile_image_name
										: `Drop files to attach or browse`
							}
						/>
					</span>
					{formik.touched.profile_image && formik.errors.profile_image && (
						<span className="error-text">{formik.errors.profile_image}</span>
					)}
				</div>
			</div>
			<div className={`col-2 pro-my-2`}>
				<div className={`${Style.root_image_inner}`}>
					<Image
						width={100}
						height={100}
						src={imageCoverPreview}
						alt={`branch - 01`}
					/>
				</div>
			</div>

			<div
				className={`col-12 pro-d-flex pro-justify-end ${isStickyFooter && "offcanvas-footer-sticky-btns"
					}`}
			>
				<Button
					className={"pro-btn-link lg pro-px-5"}
					onClick={() => closeModal()}
				>
					Cancel
				</Button>

				<Button
					className={`pro-btn-primary lg pro-ms-3 ${submitLoading ? "loading" : ""}`}
					type="submit"
					disabled={submitLoading}
					onClick={formik.handleSubmit}
				>
					{selectedItemsDetails === "" ? "Create" : "Update"}
				</Button>
			</div>
		</div>
	);
};

export default StaffsForm;
