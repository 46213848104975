import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useNoteForm from "./useNoteForm";
import Style from "./noteform.module.scss";

const NoteForm = ({ closeModal, refetch, skip }) => {
  const { setNote, handleSubmitNote, NoteHistory, note } = useNoteForm({
    refetch,
    closeModal,
    skip,
  });

  return (
    <>
      <div className="pro-w-100 pro-mb-3">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Note*
        </label>
        <textarea
          type="text"
          id="closing_reason"
          rows={4}
          cols={40}
          name="account_number"
          className="pro-input lg"
          // className={`pro-input lg ${
          //   errors && statusReason?.length <= 0 && "error"
          // }`}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </div>

      <div className={Style.followUp_list_wrap}>
        {NoteHistory?.data?.data?.map((data) => {
          return (
            <div className={Style.item}>
              <div className={`${Style.list_item}`}>
                <p
                  className={`pro-font-xs pro-fw-medium pro-mb-0 ${Style.date}`}
                >
                  {data?.created_at}
                </p>
                <div className={`${Style.list_item_wrap} pro-px-5 pro-py-4`}>
                  {/* badge-success-outline || badge-warning-outline || badge-danger-outline */}
                  <p className="pro-font-sm pro-mb-0">{data?.note}</p>
                  <div className="pro-font-sm ">
                    <p className="pro-mt-3 pro-mb-0">
                      <span className={Style.create_by}>Created By : </span>
                      <span className={Style.create_name}>
                        {data?.created_by}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className={`col-12 pro-d-flex pro-justify-end pro-pt-5`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          onClick={handleSubmitNote}
        >
          {"Submit"}
        </Button>
      </div>
    </>
  );
};

export default NoteForm;
