import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { emailRegExp, phoneRegExp } from "../../../../utils/functions/table";
import { updateConfig } from "../../../../store/slices/Global";
// import countryData from "../../../../utils/components/countryCode";
import { useGetProfileDataQuery } from "../../../../store/queries/profile";
import useValidations from "../../../../utils/hooks/useValidations";
import { updateProfileData } from "../../../../store/slices/Profile/profileSlice";
import { resetPassword } from "../../../Auth/api";
import { useGetBasicDataQuery } from "../../../../store/queries/global";

const useProfileEdit = ({ setProfileViewVisible,handleLogout }) => {
  const { validPasswords } = useValidations();
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState("");
  const profilefileInputRef = useRef(null);
  // const [userData, setUserData] = useState(null);
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [showCurrentPassword, setShowCurrentPassword] = useState(true);

  const { data: userData = {}, refetch } = useGetProfileDataQuery({});

  const [imagePreview, setImagePreview] = useState(
    userData !== "" ? userData?.data?.profile_image : ""
  );

  const { data: basicData = {} } = useGetBasicDataQuery();

  const imageFileInputRef = useRef(null);

  const dispatch = useDispatch();

  var validationSchemaProfileEdit = Yup.object({
    name: Yup.string(),
    gender: Yup.mixed(),
    email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email")
      .required("*Required"),

    phone_number: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(10, "Phone number must be 10 digits")
      .max(10, "Phone number must be 10 digits")
      .required("*Required"),
  });

  var validationSchemaPasswordChange = Yup.object({
    current_password: Yup.mixed().required("current password required"),
    new_password: Yup.mixed().required("New password is required"),
    confirm_password: Yup.mixed().required("confirm password is required"),
  });

  const [selectedOption, setSelectedOption] = useState(null);

  const formikEditUserData = useFormik({
    initialValues: {
      // profile_image: userData?.data?.profile_image,
      name: userData?.data?.name,
      gender: userData?.data?.gender,
      email: userData?.data?.email,
      country_code: userData?.data?.country_code,
      phone_number: userData?.data?.phone_number,
    },

    validationSchema: validationSchemaProfileEdit,
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setFieldError }) => {
      handleFormSubmit(values, resetForm, setFieldError);
    },
  });

  const formikEditPassword = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      confirm_password: "",
    },

    validate: (values) => {
      let errors = {};
      const {
        uppercasePassword,
        lowercasePassword,
        digitsPassword,
        minLengthPassword,
        charsPassword,
      } = validPasswords(values.new_password);
      if (!values.new_password) {
        errors.password = "*New Password required";
      }
      if (!values.confirm_password) {
        errors.confirm_password = "*Password confirmation required";
      }
      if (values.new_password !== values.confirm_password) {
        errors.confirm_password = "*Passwords does not match";
      }
      if (!minLengthPassword) {
        errors.new_password = "*Password should be atleast 8 digits";
      }
      if (!charsPassword) {
        errors.new_password = "*Enter atleast 1 special character";
      }
      if (!digitsPassword) {
        errors.new_password = "*Enter atleast 1 digit";
      }
      if (!lowercasePassword) {
        errors.new_password = "*Enter atleast 1 lowercase letter";
      }

      if (!uppercasePassword) {
        errors.new_password = "*Enter atleast 1 uppercase letter";
      }

      return errors;
    },

    validationSchema: validationSchemaPasswordChange,
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setFieldError }) => {
      handleSubmitPasswordChange(values, resetForm, setFieldError);
    },
  });

  const handleFormSubmit = async (values, resetForm, setFieldError) => {
    try {
      let obj = {
        name: values?.name,
        phone_number: values.phone_number,
        country_code: values.country_code,
        email: values.email,
        gender: values?.gender?.id,
      };

      if (values?.profile_image) {
        obj.profile_image = values.profile_image;
      }

      let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      formData.append("_method", "PUT");

      dispatch(updateProfileData(formData))
        .then((data) => {
          if (data?.payload?.success === true) {
            dispatch(
              updateConfig((state) => {
                state.isProfileViewVisible = false;
              })
            );
            refetch();
            resetForm();
            toast.success("Your Profile has been updated successfully.");
          } else if (data?.payload?.success === false) {
            const profileImageError = data?.payload?.data?.profile_image[0];
            if (profileImageError) {
              toast.error(profileImageError);
            }
          }
        })
        .catch((err) => {
          toast.error("Failed to Update");
          // if (err?.message?.groom_name?.[0] !== "") {
          //   setFieldError("groomName", err?.message?.groom_name?.[0]);
          // }
        });
    } catch (error) {}
  };

  const handleSubmitPasswordChange = async (
    values,
    resetForm,
    setFieldError
  ) => {
    try {
      try {
        let obj = {
          current_password: values?.current_password,
          new_password: values?.new_password,
          confirm_password: values?.confirm_password,
        };

        let formData = new FormData();
        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });

        resetPassword(formData)
          .then((data) => {

            if (data?.data?.success === true) {
              dispatch(
                updateConfig((state) => {
                  state.isProfileViewVisible = false;
                })
              );
              localStorage.setItem(
                "USER_ACCESS_TOKEN",
                data?.data?.data?.token
              );
              sessionStorage.setItem(
                "USER_ACCESS_TOKEN",
                data?.data?.data?.token
              );
              resetForm();
              toast.success("Your password has been updated successfully.");
            handleLogout();
            }
          })
          .catch((err) => {
            if (err?.message?.password?.[0] !== "") {
              setFieldError("password", err?.message?.password?.[0]);
            }
          });
      } catch (error) {}
    } catch (error) {
      toast.success("Failed to Update!");
    }
  };

  const handleShowCurrentPassword = (e) => {
    e.preventDefault();
    setShowCurrentPassword(!showCurrentPassword);
    var x = document.getElementById("current_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  const handleShowNewPassword = (e) => {
    e.preventDefault();
    setShowNewPassword(!showNewPassword);
    var x = document.getElementById("new_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
    var x = document.getElementById("confirm_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleProfileImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formikEditUserData.setFieldValue("profile_image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
        formikEditUserData.handleSubmit();
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The profile image size must not exceed 5 MB");
    }
  };

  return {
    message,
    setLoading,
    setMessage,
    setOptions,
    // cuntryCodeOptions,
    imagePreview,
    profilefileInputRef,
    formikEditUserData,
    formikEditPassword,
    loading,
    showCurrentPassword,
    showNewPassword,
    showConfirmPassword,
    handleProfileImage,
    handleShowCurrentPassword,
    handleShowNewPassword,
    handleShowConfirmPassword,
    imageFileInputRef,
    selectedOption,
    setSelectedOption,
    options,
    isDataLoading,
    setIsDataLoading,
    userData,
    handleSubmitPasswordChange,
    basicData,
  };
};

export default useProfileEdit;
