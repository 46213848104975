import Assets from "../../../assets/Assets";
import Style from "./adminProfileCard.module.scss";

const AdminProfileCard = ({
  userData,
  profilefileInputRef,
  handleProfileImage,
  imagePreview,
  formikEditUserData,
}) => {
  const handleClick = () => {
    profilefileInputRef.current.click();
  };

  return (
    <div className={Style.root}>
      <div className={Style.img_wrap}>
        <figure className="pro-mb-0">
          {userData?.data?.profile_image ? (
            <img src={userData?.data?.profile_image} alt="admin_image" />
          ) : userData?.data?.profile_image === null &&
            userData?.full_name === "Super Admin" ? (
            <img src={Assets.ACCOUNT_CIRCLE} alt="admin_image" />
          ) : (
            <img src={Assets.GENDER_IMAGE} alt="admin_image" />
          )}
        </figure>
        {userData?.full_name !== "Super Admin" ? (
          <span className={Style.editProfile} onClick={handleClick}>
            {" "}
            <span class="material-symbols-outlined">edit</span>
          </span>
        ) : (
          <></>
        )}
      </div>
      <div className="pro-ps-4 pro-pt-1 pro-pb-2 pro-d-flex pro-flex-column">
        {userData?.data?.name && (
          <h4 className="pro-ttl h4 pro-mb-1">{userData?.data?.name ?? " "}</h4>
        )}
        {
          <p className="pro-mb-2" {...formikEditUserData.getFieldProps("name")}>
            {userData?.data?.role_details?.name}
          </p>
        }
        {
          <p
            className="pro-mb-2"
            {...formikEditUserData.getFieldProps("gender")}
          >
            {userData?.data?.gender?.name}
          </p>
        }
      </div>

      <div className="input-wrap pro-mb-4">
        <div className="input-drag">
          <input
            type="file"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${" error"}`}
            style={{ display: "none" }}
            id="profile_image"
            name="profile_image"
            onChange={(e) => handleProfileImage(e)}
          />
        </div>
        {formikEditUserData.touched.profile_image &&
          formikEditUserData.errors.profile_image && (
            <span className="error-text">
              {formikEditUserData.errors.profile_image}
            </span>
          )}
      </div>
    </div>
  );
};

export default AdminProfileCard;
