import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  useGetOrderListeDataQuery,
  useUpdateStatusMutation,
  useGetPaymentSummaryQuery,
  useUpdateTableFieldsDataMutation,
} from "../../../store/queries/orders";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { getUserEditData } from "../../../store/slices/user/userSlice";
import { updateConfig } from "../../../store/slices/orders/orderSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { exportOrderReport, updatePaymentStatus } from "./api";
import moment from "moment";
import Select from "react-select";
import { useGetBasicDataQuery } from "../../../store/queries/global";
import { toast } from "react-toastify";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const useOrders = ({ dashboard, mainFilter, subFilter }) => {
  const navigate = useNavigate();

  const menuState = useSelector((state) => state.orders);
  localStorage.setItem("currentFilter", menuState.currentFilter);
  const { showEditModal } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const [updateTableFields] = useUpdateTableFieldsDataMutation();
  const [updateStatus] = useUpdateStatusMutation();

  const [date, setDate] = useState({
    startDate: menuState.start,
    endDate: menuState.end,
    key: "selection",
  });

  const [orderPaymentReason, setOrderPaymentReason] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [statusReason, setStatusReason] = useState("");
  const [errors, setErrors] = useState("");
  const [selectedValues, setSelectedValues] = useState({
    data: {},
    selectedOption: {},
  });
  const [skip, setSkip] = useState(true);

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetOrderListeDataQuery({
    sort_by: menuState?.sortBy,
    sort_order: dashboard ? "desc" : menuState?.sortOrder,
    search: dashboard ? "" : menuState?.search,
    page_size: dashboard ? 10 : menuState?.currentPageSize,
    page: dashboard ? 1 : menuState?.currentPage,
    filter: dashboard ? null : menuState.currentFilter || null,
    start: getFormatedDate(menuState.start),
    end: getFormatedDate(menuState.end),
    branch_id: menuState.currentBranchFilter,
    main_status:
      mainFilter !== ""
        ? mainFilter === "buy"
          ? 2
          : mainFilter === "sell"
          ? 3
          : ""
        : menuState?.currentMainStatusFilter,
    sub_status:
      subFilter !== ""
        ? subFilter === "completed"
          ? 3
          : ""
        : menuState?.currentSubStatusFilter,
  });

  const {
    data: paymentSummaryData = {},
    isFetching: isPaymentSummaryFetching,
  } = useGetPaymentSummaryQuery({
    sort_by: menuState?.sortBy,
    sort_order: dashboard ? "desc" : menuState?.sortOrder,
    search: dashboard ? "" : menuState?.search,
    page_size: dashboard ? 10 : menuState?.currentPageSize,
    page: dashboard ? 1 : menuState?.currentPage,
    filter: dashboard ? null : menuState.currentFilter || null,
    start: getFormatedDate(menuState.start),
    end: getFormatedDate(menuState.end),
    branch_id: menuState.currentBranchFilter,
    main_status:
      mainFilter !== ""
        ? mainFilter === "buy"
          ? 2
          : mainFilter === "sell"
          ? 3
          : ""
        : menuState?.currentMainStatusFilter,
    sub_status:
      subFilter !== ""
        ? subFilter === "completed"
          ? 3
          : ""
        : menuState?.currentSubStatusFilter,
  });

  const { data: basicData = {} } = useGetBasicDataQuery();
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const paymentStatus = [
    {
      label: "Pending",
      id: 1,
    },
    {
      label: "Completed",
      id: 2,
    },
  ];

  //clearing all the slice states initially
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.scrollPage = 1;
        state.currentFilter = null;
        state.currentPageSize = 10;
        state.currentBranchFilter = [];
        state.clearSelection = false;
        state.queryData = {};
        state.queryStatus = "idle";
        state.sortBy = "";
        state.sortOrder = "desc";
        state.search = "";
        state.showCreateModal = false;
        state.selectedId = "";
        state.selectedItemsDetails = "";
        state.is_edit = false;
        state.showViewModal = false;
      })
    );
    //eslint-disable-next-line
  }, []);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  //order detail view permission
  const hasViewOrderDetailsPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view_order")
    );
    return permission?.[0]?.can_view_order ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  //invoice create permissin
  const hasInvoiceCreatePermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create_invoice")
    );
    return permission?.[0]?.can_create_invoice ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  //product detail view permission
  const hasProductViewPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view_product_details")
    );
    return permission?.[0]?.can_view_product_details ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  //order export permission
  const hasExportPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );
    return permission?.[0]?.can_export ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  //order status change permission
  const hasChangeStatusPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_change_status")
    );
    return permission?.[0]?.can_change_status ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  //payment status change permission
  const hasChangePaymentPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_change_payment_status")
    );
    return permission?.[0]?.can_change_payment_status ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  //travel detail tab
  const hasTravelDetailViewPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view_travel_details")
    );
    return permission?.[0]?.can_view_travel_details ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  //document detail tab
  const hasDocumentDetailViewPermision = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view_document_details")
    );
    return permission?.[0]?.can_view_document_details ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  //payment detail tab
  const hasDeliveryDetailViewPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view_delivery_details")
    );
    return permission?.[0]?.can_view_delivery_details ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  //payment detail tab
  const hasPaymentDetailViewPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view_payment_details")
    );
    return permission?.[0]?.can_view_payment_details ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  //note add/update permission
  const hasOrderNoteAddPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_add_order_note")
    );
    return permission?.[0]?.can_add_order_note ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    ...(hasExportPermission ? [{ label: "Export", value: 1 }] : []),
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      exportOrderReport({
        fields: Object.keys(mainData?.data?.fields),
        sort_by: menuState?.sortBy,
        sort_order: menuState?.sortOrder,
      }).then((response) => {
        let dataType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleInvoiceClick = (field, data) => {
    if (hasInvoiceCreatePermission) {
      dispatch(
        updateConfig((state) => {
          state.showInvoiceModal = true;
          state.invoiceLoading = true;
          state.orderId = data?.order_id;
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      total_amount: (field, data) => (
        <p className="pro-mb-0 pro-text-end">{data[field]}</p>
      ),
      order_type: (field, data) => (
        <p
          className={`pro-mb-0 pro-badge ${
            data[field] === "Buy"
              ? "badge-success-outline"
              : "badge-danger-outline"
          }`}
        >
          {data[field]}
        </p>
      ),
      order_number: (feild, data) => {
        // return hasViewOrderDetailsPermission &&
        //   (hasProductViewPermission ||
        //     hasTravelDetailViewPermission ||
        //     hasDocumentDetailViewPermision ||
        //     hasDeliveryDetailViewPermission ||
        //     hasPaymentDetailViewPermission) ? (
        return hasViewOrderDetailsPermission ? (
          <p
            className={`pro-mb-0 pro-pnt`}
            onClick={() => {
              localStorage.setItem(
                "currentFilter",
                data?.order_type.toLowerCase()
              );
              localStorage.setItem("product_order_id", data?.order_id);

              dispatch(getUserEditData(data?.user_id)).then((response) => {
                if (response?.payload?.success) {
                  if (hasProductViewPermission) {
                    window.open(
                      `/orders/order-details/${data?.order_number}/${data?.order_id}`
                    );
                  } else if (
                    hasTravelDetailViewPermission &&
                    menuState?.currentFilter !== "sell"
                  ) {
                    window.open(
                      `/orders/order-details/travel-details/${data?.order_number}/${data?.order_id}`
                    );
                  } else if (
                    hasDocumentDetailViewPermision &&
                    menuState?.currentFilter !== "sell"
                  ) {
                    window.open(
                      `/orders/order-details/document-details/${data?.order_number}/${data?.order_id}`
                    );
                  } else if (hasDeliveryDetailViewPermission) {
                    window.open(
                      `/orders/order-details/delivery-details/${data?.order_number}/${data?.order_id}`
                    );
                  } else if (hasPaymentDetailViewPermission) {
                    window.open(
                      `/orders/order-details/order-payment-details/${data?.order_number}/${data?.order_id}`
                    );
                  } else {
                  }
                }
              });

              sessionStorage.setItem("active", `${data?.id}`);
            }}
          >
            {data?.[feild]}
          </p>
        ) : (
          <p className="pro-mb-0">{data?.[feild]}</p>
        );
      },

      status: (field, data) => {
        let paymentValue = data["is_payment_completed"] === true ? 2 : 1;

        let selectVal = basicData?.data?.order_status.find(
          (item) =>
            item.id ===
            (data[field] === "In progress"
              ? 2
              : data[field] === "Completed"
              ? 3
              : data[field] === "Cancelled"
              ? 4
              : data[field] === "Pending"
              ? 1
              : "")
        );

        return hasChangeStatusPermission && !dashboard && selectVal ? (
          <Select
            id="status"
            name="status"
            placeholder={"Select"}
            className={`pro-input lg table-ddl  pro-badge ${
              (data[field] === "Pending" && "badge-pending-outline") ||
              (data[field] === "In progress" && "badge-inprogress-ouline") ||
              (data[field] === "Completed" &&
                "badge-complete-ouline disabled") ||
              (data[field] === "Cancelled" && "badge-cancelled-ouline")
            } `}
            classNamePrefix="pro-input"
            options={basicData?.data?.order_status}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={selectVal}
            onChange={(selectedOption) => {
              if (paymentValue === 1 && selectedOption?.id === 3) {
                toast.error(
                  "The order cannot be completed while the payment status is pending."
                );
                return;
              }

              if (selectedOption?.name === "Completed") {
                setSelectedValues({
                  data: data,
                  selectedOption: selectedOption,
                });
                setShowConfirmationModal(true);
                return;
              }
              //function to change the order status
              handleOrderStatusChange(data, selectedOption);
            }}
            menuPlacement="auto"
            menuPosition="fixed"
            isDisabled={data["status"] === "Completed"}
          />
        ) : (
          <p
            className={`pro-input lg table-ddl  pro-badge pro-mb-0 ${
              (data[field] === "Pending" && "badge-pending-outline") ||
              (data[field] === "In progress" && "badge-inprogress-ouline") ||
              (data[field] === "Completed" &&
                "badge-complete-ouline disabled") ||
              (data[field] === "Cancelled" && "badge-cancelled-ouline") ||
              (data[field] === "Incomplete" && "badge-incomplete-outline")
            } `}
          >
            {data[field]}
          </p>
        );
      },

      action: (feild, data) =>
        hasInvoiceCreatePermission === 1 &&
        !dashboard &&
        data["status"] !== "Incomplete" ? (
          <p
            className="pro-mb-0 pro-pnt"
            onClick={() => handleInvoiceClick(feild, data)}
          >
            Order Summary
          </p>
        ) : (
          <></>
        ),
      is_payment_completed: (field, data) => {
        let paymentValue = data[field] === true ? 2 : 1;
        const selectVal = paymentStatus?.find(
          (item) => item?.id === paymentValue
        );

        return hasChangePaymentPermission && !dashboard ? (
          <Select
            id="payment_status"
            name="payment_status"
            placeholder={"Select"}
            className={`pro-input lg ${
              selectVal?.id === 2 ? "pro-badge badge-complete-ouline" : ""
            }`}
            classNamePrefix="pro-input"
            options={paymentStatus}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.id}
            value={selectVal}
            isDisabled={
              selectVal?.id === 2 ||
              data["status"] === "Incomplete" ||
              data["status"] === "Cancelled"
            }
            onChange={(selectedOption) => {
              setOrderPaymentReason(true);
              setOrderId(data?.order_id);
            }}
            menuPlacement="auto"
            menuPosition="fixed"
          />
        ) : (
          <p className="pro-mb-0">
            {paymentValue === 2 ? "Completed" : "Pending"}
          </p>
        );
      },
      note: (feild, data) => (
        <p className="pro-mb-0 pro-d-flex pro-justify-between pro-gap-5">
          <span className="pro-align-self-center">{data[feild]}</span>

          {!dashboard && hasOrderNoteAddPermission ? (
            <span className="pro-align-self-center">
              <Button
                onClick={() => {
                  dispatch(
                    updateConfig((state) => {
                      state.showNoteForm = true;
                      state.orderNoteId = data?.order_id;
                    })
                  );
                  //setting skip based on data of note, to fetch the note history
                  if (data["note"]) {
                    setSkip(false);
                  } else {
                    setSkip(true);
                  }
                }}
                className="pro-btn pro-btn-primary min"
              >
                {data["note"] ? "Update" : "Add"}
              </Button>
            </span>
          ) : (
            <></>
          )}
        </p>
      ),
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  //function to submit the confirmation box
  //and save the values of the order to change status
  const handleSubmitOrderComplete = () => {
    handleOrderStatusChange(
      selectedValues?.data,
      selectedValues?.selectedOption
    );
  };

  //function to close the confirmation box and
  //clears the values of the order details to be submitted
  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
    setSelectedValues({
      data: {},
      selectedOption: {},
    });
  };

  const handleOrderStatusChange = (data, selectedOption) => {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("order_id", data?.order_id);
    formData.append("status", selectedOption?.id);
    updateStatus(formData)
      .then((response) => {
        if (response?.data?.success) {
          toast.success("Status Updated Successfully!");
          refetch?.();
          closeConfirmationModal?.();
        } else if (!response?.data?.success) {
          toast.error("Failed to Update Status!");
          closeConfirmationModal?.();
        } else {
          toast.error("Failed to Update Status!");
          closeConfirmationModal?.();
        }
      })
      .catch(() => {
        toast.error("Failed to Update Status!");
        closeConfirmationModal?.();
      });
  };

  const filters = useMemo(() => {
    if (mainData?.data?.filters) {
      const buttonGroups = mainData?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading, mainData]);

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
      localStorage.setItem("currentFilter", filter?.value);
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
      localStorage.setItem("currentFilter", filter.value);
    }
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.start = startDate;
        state.end = endDate;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/orders");
    dispatch(
      updateConfig((state) => {
        if (dashboard) {
          state.currentFilter = null;
          state.currentPage = 1;
        } else {
          state.currentPage = 1;
        }
      })
    );
  };

  const closeInvoiceModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showInvoiceModal = false;
      })
    );
  };

  let newData = { ...mainData?.data?.fields };
  if (dashboard) {
    delete newData.action;
  }

  let OrderItems = {
    total_orders: {
      label: "total_orders",
      title: "Total Orders",
      icon: "calendar_month",
    },
    completed_orders: {
      label: "completed_orders",
      title: "Completed Orders",
      icon: "task_alt",
    },
    today_collection: {
      label: "today_collection",
      title: "Today",
      prev: "Yesterday",
      icon: "event",
    },
    weekly_collection: {
      label: "weekly_collection",
      title: "This week",
      prev: "Last week",
      icon: "date_range",
    },
    monthly_collection: {
      label: "monthly_collection",
      title: "This month",
      prev: "Last month",
      icon: "calendar_month",
    },
    yearly_collection: {
      label: "yearly_collection",
      title: "This Year",
      prev: "Last Year",
      icon: "event_note",
    },
  };

  const handleBranchFilter = (ids) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentBranchFilter = ids;
      })
    );
  };

  const handleMainStatusFilter = (value) => {
    dispatch(
      updateConfig((state) => {
        state.currentMainStatusFilter = value?.id;
        state.currentPage = 1;
      })
    );
  };

  const handleSubStatusFilter = (value) => {
    dispatch(
      updateConfig((state) => {
        state.currentSubStatusFilter = value?.id;
        state.currentPage = 1;
      })
    );
  };

  const closePaymentReasonModal = () => {
    setOrderPaymentReason(false);
    setErrors("");
    setStatusReason("");
    setOrderId("");
  };

  const handleSubmitPaymentReviewChange = () => {
    if (statusReason?.length <= 0) {
      setErrors("Payment Review is required");
    } else {
      setErrors("");
      const obj = {
        order_id: orderId,
        payment_review: statusReason,
      };

      updatePaymentStatus(obj).then((res) => {
        if (res?.status === 200) {
          toast.success("Payment status changed successfully");
          refetch();
          setOrderPaymentReason(false);
          setErrors("");
          setStatusReason("");
          setOrderId("");
        } else {
          toast.error("Failed to change  payment status");
          setOrderPaymentReason(false);
          setErrors("");
          setStatusReason("");
          setOrderId("");
        }
      });
    }
  };

  const handleCloseNoteForm = () => {
    dispatch(
      updateConfig((state) => {
        state.showNoteForm = false;
        state.orderNoteId = "";
      })
    );
  };

  //payment summary cards section

  const headerLabels = {
    completed_payment_count: "Settled Payment Count",
    settled_payment_sum: "Settled Payment ",
    all_payment_sum: "All Payments ",
    // converted_all_payment_sum: "Converted All Payments Sum",
    // converted_settled_payment_sum: "Converted Settled Payment Sum",
  };

  const HeaderValue = {
    completed_payment_count:
      paymentSummaryData?.data?.completed_payment_count !== undefined || null
        ? `${paymentSummaryData?.data?.completed_payment_count}`
        : " Unavailable",
    settled_payment_sum:
      paymentSummaryData?.data?.settled_payment_sum !== undefined || null
        ? `${"₹ " + paymentSummaryData?.data?.settled_payment_sum}`
        : " Unavailable",
    all_payment_sum:
      paymentSummaryData?.data?.all_payment_sum !== undefined || null
        ? `${"₹ " + paymentSummaryData?.data?.all_payment_sum}`
        : " Unavailable",
    // converted_all_payment_sum:
    //   paymentSummaryData?.data?.converted_all_payment_sum !== undefined || null
    //     ? `${"₹ " + paymentSummaryData?.data?.converted_all_payment_sum}`
    //     : " Unavailable",
    // converted_settled_payment_sum:
    //   paymentSummaryData?.data?.converted_settled_payment_sum !== undefined ||
    //   null
    //     ? `${"₹ " + paymentSummaryData?.data?.converted_settled_payment_sum}`
    //     : " Unavailable",
  };

  const paymentReportDetails = Object.keys(HeaderValue).map((key) => {
    return {
      label: headerLabels[key],
      value:
        typeof HeaderValue[key] === "string"
          ? HeaderValue?.[key]
          : typeof HeaderValue[key] === "number"
          ? HeaderValue?.[key]
          : HeaderValue?.[key]?.name,
    };
  });

  return {
    menuState,
    mainData,
    isLoading,
    isFetching,
    currentPage: menuState?.currentPage,
    paginationOptions,
    date,
    actionOptions,
    showEditModal,
    activeFilter: menuState.currentFilter,
    newData,
    showInvoiceModal: menuState?.showInvoiceModal,
    hasExportPermission,
    hasChangeStatusPermission,
    filters,
    OrderItems,
    basicData,
    orderPaymentReason,
    statusReason,
    errors,
    showConfirmationModal,
    skip,
    isPaymentSummaryFetching,
    paymentReportDetails,
    handleCloseNoteForm,
    handleSubmitOrderComplete,
    closeConfirmationModal,
    handleMainStatusFilter,
    handleSubStatusFilter,
    setStatusReason,
    handleSubmitPaymentReviewChange,
    closePaymentReasonModal,
    handleFilter,
    updateTableFields,
    handleSort,
    closeEditModal,
    refetch,
    getRow,
    handlePageSize,
    handlePagination,
    handleActionChange,
    handleDateRangeChange,
    handleSearch,
    handleDashboardRedirect,
    closeInvoiceModal,
    handleBranchFilter,
  };
};

export default useOrders;
