import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useProfileEdit from "./useProfileEdit";
import AddFormFields from "../AddFormFields";

const ProfileDetails = ({
  setShowform,
  refetch,
  isStickyFooter,
  handleLogout,
  setProfileViewVisible,
}) => {
  const {
    handleProfileImage,
    handleShowCurrentPassword,
    handleShowNewPassword,
    handleShowConfirmPassword,
    setIsDataLoading,
    userData,
    profilefileInputRef,
    formikEditUserData,
    formikEditPassword,
    loading,
    optionsWithIcons,
    imagePreview,
    staffList,
    selectedOption,
    showCurrentPassword,
    showNewPassword,
    cuntryCodeOptions,
    handlePhoneNumberChange,
    showConfirmPassword,
    setSelectedOption,
    options,
    handleFileChange,
    isDataLoading,
    basicData,
  } = useProfileEdit({ setProfileViewVisible, refetch, handleLogout });
  return (
    <div className={`pro-w-100`}>
      <AddFormFields
        userData={userData}
        optionsWithIcons={optionsWithIcons}
        imagePreview={imagePreview}
        handleProfileImage={handleProfileImage}
        handlePhoneNumberChange={handlePhoneNumberChange}
        cuntryCodeOptions={cuntryCodeOptions}
        staffList={staffList}
        formikEditUserData={formikEditUserData}
        formikEditPassword={formikEditPassword}
        setShowform={setShowform}
        selectedOption={selectedOption}
        profilefileInputRef={profilefileInputRef}
        setSelectedOption={setSelectedOption}
        options={options}
        handleFileChange={handleFileChange}
        isDataLoading={isDataLoading}
        setIsDataLoading={setIsDataLoading}
        setProfileViewVisible={setProfileViewVisible}
        handleShowCurrentPassword={handleShowCurrentPassword}
        handleShowNewPassword={handleShowNewPassword}
        handleShowConfirmPassword={handleShowConfirmPassword}
        showCurrentPassword={showCurrentPassword}
        showNewPassword={showNewPassword}
        showConfirmPassword={showConfirmPassword}
        basicData={basicData}
      />
      <div className={`col-12 pro-mt-4 pro-d-flex pro-justify-end`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => setProfileViewVisible(true)}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          onClick={formikEditPassword.handleSubmit}
          type="submit"
        >
          {loading ? (
            <i
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></i>
          ) : (
            ""
          )}
          Update
        </Button>
      </div>
    </div>
  );
};

export default ProfileDetails;
