import React from "react";
import useCallback from "./useCallback";
import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
const Callback = () => {
  const {
    callbackState,
    callbackData,
    paginationOptions,
    isLoading,
    isFetching,
    actionOptions,
    showEditModal,
    filters,
    activeFilter,
    handleFilter,
    updateTableFields,
    refetch,
    closeEditModal,
    handleActionChange,
    handleClearClick,
    handleSort,
    handlePageSize,
    handlePagination,
    getRow,
    handleSearch,
  } = useCallback();
  return (
    <>
      <HeadingGroup title={"Callback"} className={`pro-mb-4`} />

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col">
            <SearchFilters
              data={filters}
              activeFilter={activeFilter}
              handleButtonGroupChange={handleFilter}
              onSearchInput={handleSearch}
              showActions={false}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              actionOptions={actionOptions?.filter((item) => item.value === 0)}
              onActionOptionChange={handleActionChange}
              handleClear={handleClearClick}
              searchInputProps={{ value: callbackState?.search }}
            />
          </div>
        </div>

        {callbackData?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={callbackData?.data?.data || []}
            uniqueID={"_id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            showCheckBox={false}
            deletable={false}
            //   handleEdit={handleEditAction}
            clear={callbackState.clearSelection}
            multiSelect={false}
            assignable={false}
            fields={callbackData?.data?.fields}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={callbackState?.currentPageSize}
          />
        )}

        {callbackData?.data?.data?.length > 0 && (
          <Pagination
            currentPage={callbackState.currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === callbackState?.currentPageSize
            )}
            totalPageCount={Math.ceil(
              callbackData?.data?.total_count / callbackState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
      </div>
      <ModalLayout
        show={showEditModal}
        handleClose={closeEditModal}
        backdrop="static"
      >
        <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            refetch={refetch}
            tableFields={Object.keys(callbackData?.data?.fields ?? {}).reduce(
              (filteredObj, property) => {
                filteredObj[property] = callbackData?.data?.fields[property];
                return filteredObj;
              },
              {}
            )}
            moduleId={callbackData?.data?.module_id}
            updateData={updateTableFields}
          />
        </div>
      </ModalLayout>
    </>
  );
};

export default Callback;
