import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  useGetBranchListDataQuery,
  useUpdateTableFieldsDataMutation,
  useUpdateBranchStatusMutation,
} from "../../../store/queries/branches";
import { updateConfig } from "../../../store/slices/Branches/branchSlice";
import { useEffect, useMemo } from "react";
import { useGetBasicDataQuery } from "../../../store/queries/global";
import { useNavigate } from "react-router-dom";
import { branches } from "../../../store/queries/branches";
import { current } from "@reduxjs/toolkit";

const useBranches = () => {
  const menuState = useSelector((state) => state.branch);
  const { showCreateModal } = useSelector((state) => state.branch);
  const { showEditModal } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateTableFields] = useUpdateTableFieldsDataMutation();
  const [updateBranchStatus] = useUpdateBranchStatusMutation();
  const { data: formData = {} } = useGetBasicDataQuery();

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetBranchListDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    per_page: menuState.currentPageSize,
    page: menuState.currentPage,
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    //eslint-disable-next-line
  }, [menuState.clearSelection]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  // hello febin! sugalle?! if suganagi igot veruo?!...

  const handleEditAction = (data) => {
    const tempResponse = mainData?.data?.data?.data.find((item) => {
      return item.branch_id === data?.[0];
    });
    let tempCountry = formData.data.country.find(
      (item) => item._id === tempResponse?.country_id
    );
    let tempState = formData.data.state.find(
      (item) => item._id === tempResponse?.state_id
    );
    let tempDistrict = formData?.data?.district?.find(
      (item) => item._id === tempResponse?.district_id
    );

    const tempResponseData = {
      branch_id: tempResponse?.branch_id,
      phone_number: tempResponse?.phone_number,
      name: tempResponse?.name,
      address: tempResponse?.address,
      country: tempCountry,
      state: tempState,
      district: tempDistrict,
      status: tempResponse?.status,
      email: tempResponse?.email,
      branch_code: tempResponse?.branch_code,
      review_link: tempResponse?.review_link,
    };

    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = tempResponseData;
        state.showCreateModal = true;
        state.is_edit = true;
        state.selectedId = data?.[0];
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const hasCreatePermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [mainData]);

  const hasEditPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasBranchViewPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );
    return permission?.[0]?.can_view ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasChangeStatusPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_change_status")
    );
    return permission?.[0]?.can_change_status ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      status: (field, data) => {
        const isChecked = data?.status;

        return (
          <div className="pro-toggle">
            <div className="pro-toggle-box">
              <input
                id="status"
                name="status"
                type="checkbox"
                checked={isChecked}
                onChange={() =>
                  hasChangeStatusPermission
                    ? toggleStatusById(data?.status, data?.branch_id)
                    : undefined
                }
                disabled={!hasChangeStatusPermission}
              />
              <span></span>
            </div>
          </div>
        );
      },
      name: (feild, data) => {
        return hasBranchViewPermission ? (
          <p
            className={`pro-mb-0 pro-pnt`}
            onClick={() => {
              navigate({
                pathname: `/branches/branch-detail/${data?.branch_id}`,
              });

              dispatch(
                updateConfig((state) => {
                  state.currentRatePageSize = 10;
                  state.currentRatePage = 1;
                })
              );
              sessionStorage.setItem("active", `${data?.branch_id}`);
            }}
          >
            {data?.[feild]}
          </p>
        ) : (
          <p>{data?.[feild]}</p>
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const toggleStatusById = (status, id) => {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("branch_id", id);
    formData.append("status", status === 1 ? 0 : 1);

    updateBranchStatus(formData);
    dispatch(
      branches.util.updateQueryData(
        "getBranchListData",
        {
          sort_by: menuState.sortBy,
          sort_order: menuState.sortOrder,
          search: menuState.search,
          per_page: menuState.currentPageSize,
          page: menuState.currentPage,
        },
        (cacheEntry) => {
          let currentCache = { ...current(cacheEntry) };
          const newResult = currentCache.data.data?.data?.map((res) =>
            res.branch_id === id
              ? {
                  ...res,
                  status: status === 1 ? 0 : 1,
                }
              : res
          );
          currentCache = {
            ...currentCache,
            data: {
              ...currentCache.data,
              data: {
                ...currentCache.data.data,
                data: newResult,
              },
            },
          };
          return currentCache;
        }
      )
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  return {
    menuState,
    mainData,
    isLoading,
    isFetching,
    paginationOptions,
    hasEditPermission,
    actionOptions,
    showEditModal,
    hasCreatePermission,
    showCreateModal,
    formData,
    currentPage: menuState.currentPage,
    handleSort,
    handleEditAction,
    closeModal,
    updateTableFields,
    closeEditModal,
    handleCreateClick,
    refetch,
    getRow,
    handlePageSize,
    handlePagination,
    handleActionChange,
    handleSearch,
  };
};

export default useBranches;
