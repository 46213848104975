import React from "react";
import Style from "./travelDetails.module.scss";
import { MultiColumnTable } from "@wac-ui-dashboard/wac_component_library";
import useTravelDetails from "./useTravelDetails";
import OverviewShimmer from "../../../Global/Shimmers/OverviewShimmer";

const TravelDetails = () => {
  const { travelDetails, isFetching } = useTravelDetails();
  return (
    <div className={Style.overview_main}>
      {isFetching ? (
        <div className="pro-p-4 pro-w-100">
          <OverviewShimmer />
        </div>
      ) : (
        <>
          <div className="pro-py-5">
            <MultiColumnTable
              minWidth={`30%`}
              title={"Travel Details"}
              data={travelDetails}
              extraClassNames={`${Style.table_wrap} pro-pb-5`}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TravelDetails;
