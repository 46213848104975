import {
  HeadingGroup,
  ModalLayout,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import CreateRoleModal from "./CreateRoleModal";
import useRoles from "./useRoles";
import OrderColumn from "../../Global/OrderColumn";

const Roles = () => {
  const {
    showModal,
    roles,
    rolesState,
    isFetching,
    hasCreatePermission,
    hasEditPermission,
    hasUpdatePermission,
    showEditModal,
    handleCreateClick,
    handleSort,
    handleEdit,
    getRow,
    closeModal,
    refetch,
    closeEditModal,
    updateTableFields,
  } = useRoles();

  return (
    <>
      <HeadingGroup
        title={"Role"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission === 1 ? "Create" : ""}
        handleClick={handleCreateClick}
      />

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <Table
          data={roles?.data?.data || []}
          deletable={false}
          uniqueID={"_id"}
          fields={roles?.data?.fields}
          showCheckBox={
            hasEditPermission === 1 && hasUpdatePermission === 1 ? true : false
          }
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          handleEdit={handleEdit}
          loading={isFetching}
          perpage={rolesState?.currentPageSize}
          assignable={false}
          multiSelect={false}
        />

        <ModalLayout
          centered={false}
          show={showModal}
          handleClose={closeModal}
          title={"Create Role"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <CreateRoleModal />
        </ModalLayout>
        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(roles?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = roles?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={roles?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default Roles;
