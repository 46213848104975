import React from "react";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import useCurrencyForm from "./useCurrencyForm";
import { capitalizeOnSpace } from "../../../../utils/functions/table";
import Select from "react-select";

const CurrencyForm = ({ refetch, closeModal, isStickyFooter }) => {
  const {
    formik,
    selectedItemsDetails,
    formData,
    showIBR,
    handleChangeCurrencyCode,
    handleChangeSellForex,
    handleChangeBuyForexRate,
  } = useCurrencyForm({ refetch, closeModal });

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Currency*"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${
          formik?.errors?.name && formik?.touched?.name && "error"
        }`}
        {...formik.getFieldProps("name")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik?.errors?.name && formik?.touched?.name}
        errorMessage={formik?.errors?.name}
      />

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Code*
        </label>
        <Select
          id="currency_code"
          name="currency_code"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.currency_code &&
            formik.touched.currency_code &&
            " error"
          }`}
          classNamePrefix="pro-input"
          options={formData.data}
          getOptionLabel={(option) => option?.currency_code}
          getOptionValue={(option) => option?._id}
          value={formData.data?.filter(
            (m) => formik.values.currency_code === m?.currency_code
          )}
          onChange={(value) => {
            handleChangeCurrencyCode(value);
          }}
        />
        {formik.touched.currency_code && formik.errors.currency_code && (
          <span className="error-text">{formik.errors.currency_code}</span>
        )}
      </div>

      {showIBR && (
        <Input
          label={"IBR Value*"}
          type="text"
          id="ibr_value"
          name="ibr_value"
          className={`pro-input lg ${
            formik?.errors?.ibr_value && formik?.touched?.ibr_value && "error"
          }`}
          {...formik.getFieldProps("ibr_value")}
          onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={formik?.errors?.ibr_value && formik?.touched?.ibr_value}
          errorMessage={formik?.errors?.ibr_value}
        />
      )}

      <div className="row gx-4">
        <div className="col-6">
          <Input
            label={"Buy Rate"}
            type="text"
            id="buy_rate
            "
            name="buy_rate
            "
            className={`pro-input lg ${
              formik.errors.buy_rate && formik.touched.buy_rate && "error"
            }`}
            {...formik.getFieldProps("buy_rate")}
            disabled={true}
          />
        </div>
        <div className="col-6">
          <Input
            label={"Sell Rate"}
            type="number"
            id="sell_rate
            "
            name="sell_rate
            "
            className={`pro-input lg ${
              formik.errors.sell_rate && formik.touched.sell_rate && "error"
            }`}
            {...formik.getFieldProps("sell_rate")}
            disabled={true}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Input
            label={"Buy Markup Rate*"}
            type="number"
            id="buy_fixed_rate"
            name="buy_fixed_rate"
            className={`pro-input lg ${
              formik?.errors?.buy_fixed_rate &&
              formik?.touched?.buy_fixed_rate &&
              "error"
            }`}
            {...formik.getFieldProps("buy_fixed_rate")}
            onChange={(event) => {
              handleChangeBuyForexRate(event);
            }}
            error={
              formik?.errors?.buy_fixed_rate && formik?.touched?.buy_fixed_rate
            }
            errorMessage={formik?.errors?.buy_fixed_rate}
          />
        </div>
        <div className="col">
          <Input
            label={"Buy Fixed Rate"}
            type="number"
            id="buy_markup_value"
            name="buy_markup_value"
            className={`pro-input lg ${
              formik?.errors?.buy_markup_value &&
              formik?.touched?.buy_markup_value &&
              "error"
            }`}
            value={formik?.values?.buy_markup_value}
            disabled={true}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Input
            label={"Sell Markdown Rate*"}
            type="number"
            id="sell_fixed_rate"
            name="sell_fixed_rate"
            className={`pro-input lg ${
              formik?.errors?.sell_fixed_rate &&
              formik?.touched?.sell_fixed_rate &&
              "error"
            }`}
            {...formik.getFieldProps("sell_fixed_rate")}
            onChange={(event) => handleChangeSellForex(event)}
            error={
              formik?.errors?.sell_fixed_rate &&
              formik?.touched?.sell_fixed_rate
            }
            errorMessage={formik?.errors?.sell_fixed_rate}
          />
        </div>
        <div className="col">
          <Input
            label={"Sell Fixed Rate"}
            type="number"
            id="sell_markup_value"
            name="sell_markup_value"
            className={`pro-input lg ${
              formik?.errors?.sell_markup_value &&
              formik?.touched?.sell_markup_value &&
              "error"
            }`}
            value={formik?.values?.sell_markup_value}
            disabled={true}
          />
        </div>
      </div>

      <Input
        label={"Forex Quantity Limit*"}
        type="text"
        id="forex_quantity_limit"
        name="forex_quantity_limit"
        className={`pro-input lg ${
          formik?.errors?.forex_quantity_limit &&
          formik?.touched?.forex_quantity_limit &&
          "error"
        }`}
        {...formik.getFieldProps("forex_quantity_limit")}
        onChange={(event) => capitalizeOnSpace(event, formik?.setFieldValue)}
        error={
          formik?.errors?.forex_quantity_limit &&
          formik?.touched?.forex_quantity_limit
        }
        errorMessage={formik?.errors?.forex_quantity_limit}
      />

      {/* toggle button start  */}
      <div className="pro-toggle">
        <div className="pro-toggle-box">
          <input
            id="status"
            name="status"
            type="checkbox"
            checked={Boolean(formik?.values?.status) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "status",
                !Boolean(formik?.values?.status) ?? 1
              );
            }}
          />
          <span></span>
        </div>
        <label>Status</label>
      </div>
      {/* toggle button end  */}
      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default CurrencyForm;
