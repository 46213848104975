import { createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  start: subDays(new Date(), 30),
  end: new Date(),
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = dashboardSlice.actions;

export default dashboardSlice.reducer;
