import React from "react";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import { capitalizeOnSpace } from "../../../../utils/functions/table";
import useBranchForm from "./useBranchForm";
const BranchForm = ({ refetch, closeModal, isStickyFooter, formData }) => {
  const { formik, selectedItemsDetails } = useBranchForm({
    refetch,
    closeModal,
  });

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Branch Name*"}
        type="text"
        id="name
          "
        name="name
          "
        className={`pro-input lg ${
          formik.errors.name && formik.touched.name && "error"
        }`}
        {...formik.getFieldProps("name")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.name && formik.touched.name}
        errorMessage={formik.errors.name}
      />

      <Input
        label={"Branch Code*"}
        type="text"
        id="branch_code"
        name="branch_code"
        className={`pro-input lg ${
          formik.errors.branch_code && formik.touched.branch_code && "error"
        }`}
        {...formik.getFieldProps("branch_code")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.branch_code && formik.touched.branch_code}
        errorMessage={formik.errors.branch_code}
      />
      <Input
        label={"Phone Number*"}
        type="text"
        id="phone_number
          "
        name="phone_number
          "
        className={`pro-input lg ${
          formik.errors.phone_number && formik.touched.phone_number && "error"
        }`}
        {...formik.getFieldProps("phone_number")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.phone_number && formik.touched.phone_number}
        errorMessage={formik.errors.phone_number}
      />
      <Input
        label={"Email*"}
        type="text"
        id="email"
        name="email"
        className={`pro-input lg ${
          formik.errors.email && formik.touched.email && "error"
        }`}
        {...formik.getFieldProps("email")}
        onChange={(event) => formik?.setFieldValue("email", event.target.value)}
        error={formik.errors.email && formik.touched.email}
        errorMessage={formik.errors.email}
      />

      <Input
        label={"Review Link*"}
        type="text"
        id="review_link"
        name="review_link"
        className={`pro-input lg ${
          formik.errors.review_link && formik.touched.review_link && "error"
        }`}
        {...formik.getFieldProps("review_link")}
        onChange={(event) =>
          formik?.setFieldValue("review_link", event.target.value)
        }
        error={formik.errors.review_link && formik.touched.review_link}
        errorMessage={formik.errors.review_link}
      />
      <Input
        label={"Address*"}
        type="text"
        id="address
          "
        name="address
          "
        className={`pro-input lg ${
          formik.errors.address && formik.touched.address && "error"
        }`}
        {...formik.getFieldProps("address")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.address && formik.touched.address}
        errorMessage={formik.errors.address}
      />
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Country*
        </label>
        <Select
          id="country"
          name="country_id"
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik?.errors?.country_id && formik.touched.country_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={formData?.country}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.country_id}
          onBlur={formik.handleBlur("country_id")}
          onChange={(value) =>
            formik?.setFieldValue("country_id", value || null) &&
            formik?.setFieldValue("state_id", null) &&
            formik?.setFieldValue("district_id", null)
          }
          menuPlacement="auto"
        />
        {formik?.errors?.country_id && formik.touched.country_id && (
          <span className="error-text">{formik?.errors?.country_id}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          State*
        </label>
        <Select
          id="state"
          name="state_id"
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik?.errors?.state_id && formik.touched.state_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={formData?.state?.filter(
            (item) =>
              item.unique_country_id ===
              formik?.values?.country_id?.unique_country_id
          )}
          value={formik?.values?.state_id}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          onBlur={formik.handleBlur("state_id")}
          onChange={(value) =>
            formik?.setFieldValue("state_id", value || null) &&
            formik?.setFieldValue("district_id", null)
          }
          menuPlacement="auto"
        />
        {formik?.errors?.state_id && formik.touched.state_id && (
          <span className="error-text">{formik?.errors?.state_id}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          District*
        </label>
        <Select
          id="district"
          name="district_id"
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik?.errors?.district_id &&
            formik.touched.district_id &&
            " error"
          }`}
          classNamePrefix="pro-input"
          options={formData?.district.filter(
            (item) =>
              item?.unique_state_id ===
              formik?.values?.state_id?.unique_state_id
          )}
          value={formik?.values?.district_id}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          onBlur={formik.handleBlur("district_id")}
          onChange={(value) =>
            formik?.setFieldValue("district_id", value || null)
          }
          menuPlacement="auto"
        />
        {formik?.errors?.district_id && formik.touched.district_id && (
          <span className="error-text">{formik?.errors?.district_id}</span>
        )}
      </div>
      {/* toggle button start  */}
      <div className="pro-toggle">
        <div className="pro-toggle-box">
          <input
            id="status"
            name="status"
            type="checkbox"
            checked={Boolean(formik.values?.status) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "status",
                !Boolean(formik.values?.status) ?? 1
              );
            }}
          />
          <span></span>
        </div>
        <label>Status</label>
      </div>
      {/* toggle button end  */}
      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default BranchForm;
