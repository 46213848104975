import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import useBranches from "./useBranches";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import BranchForm from "./BranchForm";

const Branches = () => {
  const {
    mainData,
    handleSearch,
    handleEditClick,
    isLoading,
    actionOptions,
    handleClearClick,
    currentPage,
    paginationOptions,
    menuState,
    isFetching,
    hasEditPermission,
    handleEditAction,
    hasCreatePermission,
    showCreateModal,
    handleSort,
    showEditModal,
    handleCreateClick,
    closeModal,
    updateTableFields,
    refetch,
    closeEditModal,
    handlePagination,
    getRow,
    handleActionChange,
    handlePageSize,
    formData,
  } = useBranches();
  return (
    <>
      <HeadingGroup
        title={"Branches"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission === 1 ? "Add new" : ""}
        handleClick={handleCreateClick}
      />

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col">
            <SearchFilters
              data={mainData?.data?.filters}
              // onDateChange={handleDateChange}
              onSearchInput={handleSearch}
              showActions={false}
              handleActionClick={handleEditClick}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              actionOptions={actionOptions?.filter((item) => item.value === 0)}
              onActionOptionChange={handleActionChange}
              handleClear={handleClearClick}
              searchInputProps={{ value: menuState?.search }}
            />
          </div>
        </div>

        {mainData?.data?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={mainData?.data?.data?.data || []}
            uniqueID={"branch_id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            showCheckBox={hasEditPermission === 1 ? true : false}
            deletable={false}
            handleEdit={handleEditAction}
            clear={menuState?.clearSelection}
            multiSelect={false}
            assignable={false}
            fields={mainData?.data?.fields}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={menuState?.currentPageSize}
          />
        )}

        {mainData?.data?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={mainData?.data?.data?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <OffCanvasLayout
          show={showCreateModal}
          handleClose={closeModal}
          title={menuState?.is_edit ? "Update Branch" : "Add Branch"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <BranchForm
            refetch={refetch}
            closeModal={closeModal}
            formData={formData?.data}
          />
        </OffCanvasLayout>

        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(mainData?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = mainData?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={mainData?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default Branches;
