import React from "react";
import usePayments from "./usePayments";
import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import Select from "react-select";
import PaymentDetails from "./PaymentDetailsPage";
import Style from "./paymentDetails.module.scss";

const Payments = ({ dashboard = false }) => {
  const {
    paymentState,
    paymentData,
    paginationOptions,
    isLoading,
    isFetching,
    initialDateRange,
    actionOptions,
    showEditModal,
    filters,
    activeFilter,
    basicData,
    handleDashboardRedirect,
    closeViewModal,
    handleBranchFilter,
    handleFilter,
    updateTableFields,
    closeEditModal,
    refetch,
    handleActionChange,
    handleDateChange,
    handleClearClick,
    handleSort,
    handlePageSize,
    handlePagination,
    getRow,
    handleSearch,
  } = usePayments({ dashboard });

  return (
    <>
      {!dashboard && <HeadingGroup title={"Payment"} className={`pro-mb-4`} />}

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {!dashboard && (
          <div className="row">
            <div className="col">
              <SearchFilters
                data={filters}
                activeFilter={activeFilter}
                handleButtonGroupChange={handleFilter}
                onSearchInput={handleSearch}
                showActions={false}
                loading={isLoading}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                actionOptions={actionOptions?.filter(
                  (item) => item.value === 0
                )}
                onActionOptionChange={handleActionChange}
                handleClear={handleClearClick}
                searchInputProps={{ value: paymentState?.search }}
                //date picker props
                showDateRange={true}
                initialDateRange={initialDateRange}
                onDateChange={handleDateChange}
                dropDownFilter={
                  <div className="col-2">
                    <Select
                      id="branch"
                      isMulti
                      isClearable={true}
                      placeholder={"Select Branch"}
                      options={basicData?.data?.branches}
                      getOptionValue={(option) => option?._id}
                      getOptionLabel={(option) => option?.name}
                      className={`pro-input lg multi-select`}
                      classNamePrefix="pro-input"
                      value={basicData?.data?.branches?.filter((p) =>
                        paymentState?.currentBranchFilter?.includes(p._id)
                      )}
                      onChange={(value) =>
                        handleBranchFilter(value.map((v) => v._id))
                      }
                    />
                  </div>
                }
              />
            </div>
          </div>
        )}

        {paymentData?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <div
            onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
            className={!dashboard ? "pro-pt-3" : `${Style.dashboardTable}`}
          >
            <Table
              data={paymentData?.data?.data || []}
              uniqueID={"id"}
              editIcon={
                <span className="material-symbols-outlined">visibility</span>
              }
              showCheckBox={false}
              deletable={false}
              editable={false}
              clear={paymentState.clearSelection}
              multiSelect={false}
              assignable={false}
              fields={paymentData?.data?.fields}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={paymentState?.currentPageSize}
            />
          </div>
        )}

        {paymentData?.data?.data?.length > 0 && !dashboard && (
          <Pagination
            currentPage={paymentState.currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === paymentState?.currentPageSize
            )}
            totalPageCount={Math.ceil(
              paymentData?.data?.total_count / paymentState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
      </div>

      <ModalLayout
        show={showEditModal}
        handleClose={closeEditModal}
        backdrop="static"
      >
        <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            refetch={refetch}
            tableFields={Object.keys(paymentData?.data?.fields ?? {}).reduce(
              (filteredObj, property) => {
                filteredObj[property] = paymentData?.data?.fields[property];
                return filteredObj;
              },
              {}
            )}
            moduleId={paymentData?.data?.module_id}
            updateData={updateTableFields}
          />
        </div>
      </ModalLayout>

      <OffCanvasLayout
        show={paymentState?.showViewModal}
        handleClose={closeViewModal}
        title={"Payment Details"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        className={Style.offcanvas}
      >
        <PaymentDetails />
      </OffCanvasLayout>
    </>
  );
};

export default Payments;
