import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../../../../store/slices/Branches/branchSlice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateRateDetails,
  updateFormData,
} from "../../../../../../store/slices/Branches/branchRateFormSlice";
import { useEffect } from "react";
import { useGetBasicDataQuery } from "../../../../../../store/queries/global";

const useSell = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const { selectedItemsDetails } = useSelector((state) => state.branchRateForm);
  const { selectedId } = useSelector((state) => state.branch);
  const { data: formData = {} } = useGetBasicDataQuery();

  const validation = Yup.object({
    // markup_value: Yup.number()
    //   .min(0, "Value must be at least 0")
    //   .max(100, "Value must be at most 100"),

    markup_value: Yup.number().when("markup_type", {
      is: 2,
      then: (schema) =>
        schema
          .min(0, "Value must be at least 0")
          .max(100, "Value must be at most 100"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      cash_rate:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.sell_details?.Cash?.rate
          : "",
      markup_type:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.sell_details?.Cash?.markup_type
          : "",
      markup_value:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.sell_details?.Cash?.markup_value
          : "",
      cash_forex_rate:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.sell_details?.Cash?.total_amount
          : "",
      cash_status:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.sell_details?.Cash?.status !== null
            ? selectedItemsDetails?.sell_details?.Cash?.status
            : 0
          : 0,
    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        branch_currency_id: selectedId,
        type: 2,
        product: [
          {
            id: selectedItemsDetails?.sell_details?.Cash?.id,
            markup_type: values?.markup_type,
            markup_value: +values?.markup_value ?? 0,
            rate: values?.cash_rate,
            total_amount: values.cash_forex_rate,
            status: +values?.cash_status ?? 0,
          },
        ],
      };

      dispatch(updateRateDetails(obj)).then((response) => {
        if (response?.payload?.data?.success) {
          resetForm();
          closeModal?.();
          refetch();
          dispatch(
            updateConfig((state) => {
              state.showCreateModal = false;
            })
          );
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );

          toast.success(response?.payload?.data?.message);
        } else if (!response?.payload?.status) {
          if (response?.payload?.data[`product.0.total_amount`]) {
            formik.setFieldError(
              "markup_value",
              response?.payload?.data[`product.0.total_amount`]
            );
          }
        } else toast.error(response?.payload?.message);
      });
    },
  });

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const setTypeFieldValue = (id) => {
    // formik?.setFieldValue("cash_markup_type", value?.id || null)
    if (formik.values?.markup_value) {
      handleChangeCashForex(formik.values?.markup_value, id);
    }
  };

  const handleChangeCashForex = (e, id) => {
    if (e.target) {
      formik.setFieldValue("markup_value", e.target.value);
    } else {
      formik.setFieldValue("markup_value", e);
    }
    const percentage = Number(e?.target?.value ?? formik?.values?.markup_value);
    const cashRate = Number(formik.values?.cash_rate) || 0;
    const cashMarkup = Math.abs(percentage) || 0;
    let rate = 0;

    if (id === 1) {
      const formattedRate = percentage.toFixed(4);
      formik.setFieldValue("cash_forex_rate", formattedRate);
    } else if (id === 2) {
      if (percentage > 0) {
        rate = cashRate + cashRate * (cashMarkup / 100);
      } else if (percentage < 0) {
        rate = cashRate - cashRate * (cashMarkup / 100);
      } else {
        rate = cashRate;
      }
      const formattedRate = rate.toFixed(4);
      formik.setFieldValue("cash_forex_rate", formattedRate);
    } else {
      rate = cashRate - percentage;
      const formattedRate = rate.toFixed(4);
      formik.setFieldValue("cash_forex_rate", formattedRate);
    }
  };

  // updating the completed percentage of butfields
  const sellFields = ["cash_markup"];

  var completedFields = sellFields?.filter((field) => {
    return !!!formik?.errors[field];
  });

  const completedPercentageSell =
    (completedFields.length / sellFields.length) * 100;

  useEffect(() => {
    // Dispatch the action to update the Redux state
    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Sale: completedPercentageSell,
        };
      })
    );
    //eslint-disable-next-line
  }, [completedPercentageSell]);

  return {
    handleCloseModal,
    formik,
    handleChangeCashForex,
    formData,
    setTypeFieldValue,
  };
};

export default useSell;
