import Dropdown from "react-bootstrap/Dropdown";
import Style from "./HeaderActions.module.scss";
import useHeaderActions from "./useHeaderActions";
import {
  ModalLayout,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import GlobalSearch from "../../../../components/Global/GlobalSearch";
import ProfileDetails from "../../../../components/Global/ProfileView/AddForm";
import Assets from "../../../../assets/Assets";

const HeaderActions = () => {
  const {
    isModalOpen,
    userData,
    searchInputRef,
    globalState,
    lastElement,
    closeModal,
    handleSearchData,
    activeUser,
    handleLogout,
    handleViewProfile,
    handleProfileRedirect,
    handleCloseProfileView,
    setProfileViewVisible,
  } = useHeaderActions();

  return (
    <div className={`pro-ms-auto pro-d-flex pro-items-center ${Style.root}`}>
      <Dropdown>
        <Dropdown.Toggle id="profile-dropdown">
          <div className="pro-avatar lg pro-flex-shrink-0">
            {activeUser?.profile_image ? (
              <img src={activeUser.profile_image} alt="user profile" />
            ) : activeUser?.gender ? (
              activeUser.gender === 1 ? (
                <img src={Assets.MALEPROFILE} alt="male" />
              ) : activeUser.gender === 2 ? (
                <img src={Assets.FEMALEPROFILE} alt="female" />
              ) : (
                <img src={Assets.GENDER_IMAGE} alt="other" />
              )
            ) : (
              <span
                className={`material-symbols-outlined x4 ${Style.profile_icon}`}
              >
                account_circle
              </span>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={handleViewProfile}
            className="pro-d-flex pro-items-center"
          >
            <span className="material-symbols-outlined x3">
              {" "}
              account_circle{" "}
            </span>
            <span className="pro-ms-2">Profile</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleLogout}
            className="pro-d-flex pro-items-center"
          >
            <span className="material-symbols-outlined x3"> logout </span>
            <span className="pro-ms-2">Logout</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <OffCanvasLayout
        show={globalState.isProfileViewVisible}
        handleClose={handleCloseProfileView}
        title={"Profile"}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
        backdrop="static"
      >
        <ProfileDetails
          setProfileViewVisible={setProfileViewVisible}
          isStickyFooter
          handleLogout={handleLogout}
          // userData={subAdminState?.profileDetails}
        />
      </OffCanvasLayout>

      <ModalLayout
        handleClose={closeModal}
        show={isModalOpen}
        backdrop="static"
      >
        <GlobalSearch
          handleSearchData={handleSearchData}
          lastElement={lastElement}
          searchKey={globalState.searchKey}
          userData={userData}
          ref={searchInputRef}
          handleProfileRedirect={handleProfileRedirect}
        />
      </ModalLayout>
    </div>
  );
};

export default HeaderActions;
