import React from "react";
import { Input, Button } from "@wac-ui-dashboard/wac_component_library";
import useBuy from "./useBuy";
import { capitalizeOnSpace } from "../../../../../../utils/functions/table";
import Select from "react-select";
import Style from "../branchRateForm.module.scss";

const Buy = ({ refetch, closeModal }) => {
  const {
    formik,
    formData,
    handleCloseModal,
    handleChangeCashForex,
    handleChangeTravelForex,
    setTypeFieldValue,
    setTravelTypeFieldValue,
  } = useBuy({ refetch, closeModal });
  return (
    <div className="row">
      <h6 className="prottl h6 pro-mb-4">Cash Details</h6>
      <div className="row gx-4">
        <div className="col">
          <Input
            label={"Cash Rate*"}
            type="text"
            id="cash_rate
            "
            name="cash_rate
            "
            className={`pro-input lg ${
              formik.errors.cash_rate && formik.touched.cash_rate && "error"
            }`}
            {...formik.getFieldProps("cash_rate")}
            disabled={true}
          />
        </div>

        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="current_location"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Cash Margin Type*
          </label>
          <Select
            id="cash_markup_type"
            name="cash_markup_type"
            isClearable={false}
            placeholder={"Select"}
            className={`pro-input lg ${
              formik.errors.cash_markup_type &&
              formik.touched.cash_markup_type &&
              " error"
            }`}
            classNamePrefix="pro-input"
            options={formData?.data?.margin_type}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formData?.data?.margin_type?.filter(
              (m) => formik.values.cash_markup_type === m?.id
            )}
            onChange={(value) => {
              formik?.setFieldValue("cash_markup_type", value?.id || null);
              setTypeFieldValue(value?.id);
            }}
          />
          {formik.touched.cash_markup_type &&
            formik.errors.cash_markup_type && (
              <span className="error-text">
                {formik.errors.cash_markup_type}
              </span>
            )}
        </div>

        <div className="col">
          <Input
            label={
              <div className="pro-d-flex">
                Cash Markup*{" "}
                {formik?.values?.cash_markup_type === 3 ? "(in Paisa)" : ""}
                {formik?.values?.cash_markup_type === 3 && (
                  <div className={Style.tooltip}>
                    <span className="material-symbols-outlined x3 pro-ms-2 ">
                      info
                    </span>
                    <span className={Style.tooltiptext}>
                      Whole numbers represent rupees (e.g., 1 = 1 rupee)
                      <br />
                      Decimal numbers represent paisa (e.g., 0.1 = 10 paisa)
                    </span>
                  </div>
                )}
              </div>
            }
            type="text"
            id="cash_markup_value"
            name="cash_markup_value"
            className={`pro-input lg ${
              formik.errors.cash_markup_value &&
              formik.touched.cash_markup_value &&
              "error"
            }`}
            {...formik.getFieldProps("cash_markup_value")}
            onChange={(e) =>
              handleChangeCashForex(e, formik?.values?.cash_markup_type)
            }
            error={
              formik.errors.cash_markup_value &&
              formik.touched.cash_markup_value
            }
            errorMessage={formik.errors.cash_markup_value}
          />
        </div>

        <Input
          label={"Cash Forex Rate*"}
          type="text"
          id="cash_forex_rate
          "
          name="cash_forex_rate
          "
          className={`pro-input lg ${
            formik.errors.cash_forex_rate &&
            formik.touched.cash_forex_rate &&
            "error"
          }`}
          {...formik.getFieldProps("cash_forex_rate")}
          // error={formik.errors.cash_forex_rate && formik.touched.cash_forex_rate}
          // errorMessage={formik.errors.cash_forex_rate}
          disabled={true}
        />
      </div>

      <div className="pro-toggle pro-mb-6">
        <div className="pro-toggle-box">
          <input
            id="cash_status"
            name="cash_status"
            type="checkbox"
            checked={Boolean(formik.values?.cash_status) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "cash_status",
                !Boolean(formik.values?.cash_status) ?? 0
              );
            }}
          />
          <span></span>
        </div>
        <label>Status</label>
      </div>

      <h6 className="pro-mb-4 pro-ttl h6">Travel Card Details</h6>
      <div className="row gx-4">
        <div className="col">
          <Input
            label={"Travel Card*"}
            type="text"
            id="travel_card
              "
            name="travel_card
              "
            className={`pro-input lg ${
              formik.errors.travel_card && formik.touched.travel_card && "error"
            }`}
            {...formik.getFieldProps("travel_card")}
            onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
            error={formik.errors.travel_card && formik.touched.travel_card}
            errorMessage={formik.errors.travel_card}
            disabled={true}
          />
        </div>
        <div className={"input-wrap pro-mb-5"}>
          <label
            htmlFor="current_location"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Travel Margin Type*
          </label>
          <Select
            id="travel_markup_type"
            name="travel_markup_type"
            isClearable={false}
            placeholder={"Select"}
            className={`pro-input lg ${
              formik.errors.travel_markup_type &&
              formik.touched.travel_markup_type &&
              " error"
            }`}
            classNamePrefix="pro-input"
            options={formData?.data?.margin_type}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formData?.data?.margin_type?.filter(
              (m) => formik.values.travel_markup_type === m?.id
            )}
            onChange={(value) => {
              formik?.setFieldValue("travel_markup_type", value?.id || null);
              setTravelTypeFieldValue(value?.id);
            }}
          />
          {formik.touched.travel_markup_type &&
            formik.errors.travel_markup_type && (
              <span className="error-text">
                {formik.errors.travel_markup_type}
              </span>
            )}
        </div>
        <div className="col">
          <Input
            label={
              <div className="pro-d-flex">
                Travel Card Markup*{" "}
                {formik?.values?.travel_markup_type === 3 ? "(in Paisa)" : ""}
                {formik?.values?.travel_markup_type === 3 && (
                  <div className={Style.tooltip}>
                    <span className="material-symbols-outlined x3 pro-ms-2 ">
                      info
                    </span>
                    <span className={Style.tooltiptext}>
                      Whole numbers represent rupees (e.g., 1 = 1 rupee)
                      <br />
                      Decimal numbers represent paisa (e.g., 0.1 = 10 paisa)
                    </span>
                  </div>
                )}
              </div>
            }
            type="text"
            id="travel_markup_value
              "
            name="travel_markup_value
              "
            className={`pro-input lg ${
              formik.errors.travel_markup_value &&
              formik.touched.travel_markup_value &&
              "error"
            }`}
            {...formik.getFieldProps("travel_markup_value")}
            onChange={(e) =>
              handleChangeTravelForex(e, formik?.values?.travel_markup_type)
            }
            error={
              formik.errors.travel_markup_value &&
              formik.touched.travel_markup_value
            }
            errorMessage={formik.errors.travel_markup_value}
          />
        </div>

        <Input
          label={"Travel Card Forex Rate*"}
          type="text"
          id="travel_forex_rate
          "
          name="travel_forex_rate
          "
          className={`pro-input lg ${
            formik.errors.travel_forex_rate &&
            formik.touched.travel_forex_rate &&
            "error"
          }`}
          {...formik.getFieldProps("travel_forex_rate")}
          // onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          // error={formik.errors.travel_forex_rate && formik.touched.travel_forex_rate}
          // errorMessage={formik.errors.travel_forex_rate}
          disabled={true}
        />
      </div>

      <div className="pro-toggle">
        <div className="pro-toggle-box">
          <input
            id="travel_status"
            name="travel_status"
            type="checkbox"
            checked={Boolean(formik.values?.travel_status) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "travel_status",
                !Boolean(formik.values?.travel_status) ?? 0
              );
            }}
          />
          <span></span>
        </div>
        <label>Status</label>
      </div>

      <div className={`col-12 pro-d-flex pro-justify-end pro-pt-4`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik.handleSubmit}
          type="button"
        >
          {"Update"}
        </Button>
      </div>
    </div>
  );
};

export default Buy;
