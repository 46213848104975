import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useGetListDataQuery,
  useUpdateTableFieldsDataMutation,
} from "../../../store/queries/roles";
import { updateConfig } from "../../../store/slices/Roles/rolesSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  UserRow,
  SettingsIcon,
  Dashboard,
  OrderIcon,
  BranchIcon,
  PaymentIcon,
} from "./Rows";

const useRoles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rolesState = useSelector((state) => state.roles);

  const { data: roles = {}, isFetching, refetch } = useGetListDataQuery({});

  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [showModal, setShowModal] = useState(false);
  const [updateTableFields] = useUpdateTableFieldsDataMutation();

  const hasCreatePermission = useMemo(() => {
    let permission = roles?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );
    return permission?.[0]?.can_create ?? 0;
  }, [roles]);
  const hasEditPermission = useMemo(() => {
    let permission = roles?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit ?? 0;
  }, [roles]);
  const hasUpdatePermission = useMemo(() => {
    let permission = roles?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update ?? 0;
  }, [roles]);

  const handleSort = (label) => {
    if (rolesState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = rolesState.sortOrder === "asc" ? "asc" : "desc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCreateClick = () => {
    setShowModal(true);
  };

  const handleEdit = (item) => {
    navigate({
      pathname: "/configure/role/permission",
      search: `role=${item[0]}&update=true`,
    });
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      dashboard: (_, data) => <Dashboard data={data} />,
      user: (_, data) => <UserRow data={data} />,
      order: (_, data) => <OrderIcon data={data} />,
      branch: (_, data) => <BranchIcon data={data} />,
      configure: (_, data) => <SettingsIcon data={data} />,
      payment: (_, data) => <PaymentIcon data={data} />,
      // support: (_, data) => <NotificationIcon data={data} />,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };
  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  return {
    roles,
    rolesState,
    isFetching,
    showModal,
    hasCreatePermission,
    hasEditPermission,
    hasUpdatePermission,
    actionOptions,
    showEditModal,
    tableFields,
    handleCreateClick,
    handleSort,
    handleEdit,
    getRow,
    closeModal,
    handleEditClick,
    handleActionChange,
    refetch,
    closeEditModal,
    updateTableFields,
  };
};

export default useRoles;
